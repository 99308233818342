import axios from "axios";
import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUsers, useAlerts } from "common";
import { GET_FILTERS_BENCHMARKS_DEPTS_DIVISIONS_HBUSERS_URL } from "app/shared/constants";
import { isValidUser, isStudiesLocation } from "app/shared/utils";

/**
 * The purpose of this filterService is to fetch all the data needed for study filter form
 * the axios calls will be called at same time. For performance reason, should be called after
 * getStudies API call since user will not open the StudyFilter form till StudyListing rendered.
 */

const FiltersContext = createContext();

export const FilterProvider = ({ children }) => {
  const { currentUser } = useUsers();
  const { setAlert } = useAlerts();
  const location = useLocation();
  const [filterData, setFilterData] = useState({});
  const [initialLoad, setInitailLoad] = useState(false);

  /**
   * Calling backend APIs to fetch filter data required by Filter form.
   * including: contacts, tags, honest brokers,
   */

  useEffect(() => {
    if (isValidUser(currentUser) && isStudiesLocation(location.pathname)) {
      (async () => {
        try {
          const response = await axios.get(
            GET_FILTERS_BENCHMARKS_DEPTS_DIVISIONS_HBUSERS_URL
          );

          setFilterData(response.data);
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        } finally {
          setInitailLoad(true);
        }
      })();
    }
  }, [currentUser, location.pathname, setAlert]);

  return (
    <FiltersContext.Provider
      value={{
        filterData,
        initialLoad,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  return useContext(FiltersContext);
};
