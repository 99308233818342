import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  FormLabel as MuiFormLabel,
  TextField as MuiTextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from "@material-ui/pickers";
import { format, isPast, isValid } from "date-fns";
import { useFormikContext } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import AntSwitch from "app/shared/UI/AntSwitch";
import { useStyles } from "app/study/CreateEditStudyFormStyles";
import {
  defaultFUDateFrom,
  defaultDateTo,
  RecruitmentStatusOptions,
} from "app/shared/constants";
import WarningDialog from "app/shared/UI/PEWarningDialog";

const CreateEditStudyMain = ({ setIsOptimizationEnabled }) => {
  const { values, setFieldValue, errors, handleBlur, touched } =
    useFormikContext();

  const { t } = useTranslation();
  const classes = useStyles();
  const [openASConfirmation, setOpenASConfirmation] = useState(false);

  const handleFollowUpDateValidation = (date) => {
    // Validation for updating a study
    return format(new Date(date), "MM/dd/yyyy") !==
      format(new Date(), "MM/dd/yyyy") &&
      values?.followUpDate?.getTime() !== date.getTime() //this condition is to not trigger validation on initial followup date & today's date.
      ? !isPast(new Date(date))
        ? setOpenASConfirmation(false)
        : setOpenASConfirmation(true)
      : true;
  };
  const closeCancelASConfirmation = () => {
    setOpenASConfirmation(false);
  };

  return (
    <>
      <WarningDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        title={t("formValidation.followUpDateTitle")}
        primaryMessage={t("formValidation.followUpDate")}
        secondaryMessage={t("formValidation.secondaryFollowUpDate")}
      />
      <MuiGrid container justify="space-between" spacing={2}>
        <MuiGrid item xs={6}>
          <MuiTextField
            name="nickName"
            fullWidth
            label={t(`formLabel.studyNickname`)}
            variant="outlined"
            onChange={(e) => {
              setFieldValue("nickname", e.target.value);
            }}
            size="small"
            value={values.nickname}
          />
        </MuiGrid>
        <MuiGrid item xs={6}>
          <MuiTextField
            name="title"
            fullWidth
            label={t(`formLabel.studyTitle`)}
            value={values.title}
            variant="outlined"
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue("title", e.target.value);
            }}
            size="small"
            error={touched.title && Boolean(errors.title)}
            helperText={
              touched.title && errors.title && t("formValidation.requiredField")
            }
          />
        </MuiGrid>
        <MuiGrid item xs={6}>
          <MuiTextField
            name="oncoreStatus"
            fullWidth
            label={t(`formLabel.oncoreProtocolStatus`)}
            variant="outlined"
            value={values.protocolStatus || ""}
            size="small"
            disabled
          />
        </MuiGrid>
        <MuiGrid item xs={6}>
          <MuiTextField
            name="oncoreProtocolNumber"
            fullWidth
            label={t(`formLabel.oncoreProtocolNumber`)}
            variant="outlined"
            value={values.protocolNumber || ""}
            size="small"
            disabled
          />
        </MuiGrid>

        <MuiGrid item xs={3}>
          <MuiTextField
            name="riskCount"
            fullWidth
            label={t(`formLabel.riskCount`)}
            variant="outlined"
            value={values.riskCount || 0}
            size="small"
            disabled
          />
        </MuiGrid>
        <MuiGrid item xs={3}>
          <MuiTextField
            name="recruited"
            fullWidth
            label={t(`formLabel.recruited`)}
            variant="outlined"
            value={
              values.recruited +
              " (" +
              values.subjectSummary?.enrolled +
              "/" +
              values.protocolTargetAccrual +
              ")"
            }
            size="small"
            disabled
          />
        </MuiGrid>
        <MuiGrid item xs={3}>
          <MuiTypography component="div">
            <MuiFormLabel component="legend" style={{ marginBottom: 5 }}>
              {t("formLabel.suspended")}
            </MuiFormLabel>
            <MuiGrid
              id="suspendedSwitch"
              component="label"
              container
              alignItems="center"
              spacing={1}
            >
              <MuiGrid item>{t("formLabel.switchNo")}</MuiGrid>
              <MuiGrid item>
                <AntSwitch
                  name="suspended"
                  checked={values.suspended}
                  value={values.suspended}
                  onChange={(e) => {
                    setFieldValue("suspended", e.target.checked);
                  }}
                />
              </MuiGrid>
              <MuiGrid item>{t("formLabel.switchYes")}</MuiGrid>
            </MuiGrid>
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={3}>
          <MuiTextField
            select
            fullWidth
            name="honestBrokerRecruitmentStatus"
            label={t(`formLabel.recruitmentStatus`)}
            variant="outlined"
            size="small"
            value={values.honestBrokerRecruitmentStatus}
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue("honestBrokerRecruitmentStatus", e.target.value);
            }}
            defaultValue={RecruitmentStatusOptions[0]}
            error={
              touched.honestBrokerRecruitmentStatus &&
              errors.honestBrokerRecruitmentStatus
            }
            helperText={
              touched.honestBrokerRecruitmentStatus &&
              errors.honestBrokerRecruitmentStatus &&
              t("formValidation.uninitiatedError")
            }
          >
            {RecruitmentStatusOptions &&
              RecruitmentStatusOptions.map((item) => (
                <MuiMenuItem key={item} value={item}>
                  {t("recruitmentStatus." + item)}
                </MuiMenuItem>
              ))}
          </MuiTextField>
        </MuiGrid>

        <MuiGrid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              name="followUpDate"
              disableToolbar
              autoOk
              fullWidth
              variant="inline"
              inputVariant="outlined"
              margin="dense"
              className={classes.datepicker}
              format="MM/dd/yyyy"
              label={t(`formLabel.followUpDate`)}
              minDate={defaultFUDateFrom}
              maxDate={defaultDateTo}
              required={true}
              onChange={(date) => {
                if (isValid(new Date(date))) {
                  handleFollowUpDateValidation(date);
                }
                setFieldValue("followUpDate", date);
              }}
              value={values.followUpDate}
              rules={{ required: true }}
            />
          </MuiPickersUtilsProvider>
        </MuiGrid>
        <MuiGrid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              disableToolbar
              autoOk
              fullWidth
              variant="inline"
              inputVariant="outlined"
              margin="dense"
              className={classes.datepicker}
              format="MM/dd/yyyy"
              label={t(`formLabel.irbExpirationDate`)}
              value={
                values.protocolIrbExpirationDate
                  ? values.protocolIrbExpirationDate
                  : null
              }
              disabled
            />
          </MuiPickersUtilsProvider>
        </MuiGrid>
        <MuiGrid item xs={6} className={classes.customGridMargin}>
          <MuiTextField
            name="note"
            fullWidth
            label={t(`formLabel.note`)}
            variant="outlined"
            multiline={true}
            size="small"
            value={values.note}
            onChange={(e) => {
              setFieldValue("note", e.target.value);
            }}
          />
        </MuiGrid>
        {values && !values.protocolSummaryAccrual ? (
          <MuiGrid item xs={6}>
            <MuiTypography component="div">
              <MuiFormLabel component="legend" style={{ marginBottom: 5 }}>
                {t("targetAccrualsTable.subpopulation_optimization_label")}
              </MuiFormLabel>
              <MuiGrid
                id="optimizationSwitch"
                component="label"
                container
                alignItems="center"
                spacing={1}
              >
                <MuiGrid item>{t("formLabel.switchNo")}</MuiGrid>
                <MuiGrid item>
                  <AntSwitch
                    name="isOptimizationEnabled"
                    checked={values.targetAccruals?.isOptimizationEnabled}
                    value={
                      !values.protocolSummaryAccrual &&
                      !!values.targetAccruals?.isOptimizationEnabled
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "targetAccruals.isOptimizationEnabled",
                        e.target.checked
                      );

                      setIsOptimizationEnabled(e.target.checked);
                    }}
                    disabled={values.protocolSummaryAccrual}
                  />
                </MuiGrid>
                <MuiGrid item>{t("formLabel.switchYes")}</MuiGrid>
              </MuiGrid>
            </MuiTypography>
          </MuiGrid>
        ) : (
          ""
        )}
      </MuiGrid>
    </>
  );
};

export default CreateEditStudyMain;
