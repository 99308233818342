import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  Alert,
  Body,
  Footer,
  Header,
  Navbar,
  useAlerts,
  useUsers,
} from "common";
import { Routes } from "app/Routes";
import { navbar } from "app/shared/config";
import { isValidUser } from "app/shared/utils";

export const App = () => {
  const { alert } = useAlerts();
  const history = useHistory();
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const location = useLocation();
  const route = location.pathname + location.search;
  const [navbarConfig, setNavbarConfig] = useState({
    route,
    navbarItems: navbar,
  });
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (isValidUser(currentUser)) {
      let navbarItems = {};
      navbarItems.width = navbar.width;
      navbarItems.items = [navbar.items];

      setNavbarConfig((state) => {
        return {
          route: state.route,
          navbarItems,
        };
      });
    }
  }, [currentUser]);

  //This useEffect is implemented to hide the application alert and display only some component level alert.
  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.fromOutreachDetailsPopup
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [history.location]);

  return (
    <>
      <Header
        logoTitle={t("app.logoTitle")}
        logoSubtitle={t("app.logoSubtitle")}
        userDisplayName={currentUser.displayName}
      />
      <Body>
        <Navbar
          menuItems={navbar.items}
          width={navbar.width}
          history={history}
          navbarConfig={navbarConfig}
        />
        {show && alert.exists && <Alert />}
        {currentUser.isLoaded && <Routes />}
      </Body>
      <Footer
        copyrightYear={t("app.copyrightYear")}
        buildVersion={process.env.REACT_APP_VERSION}
      />
    </>
  );
};
