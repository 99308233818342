import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl as MuiFormControl,
  OutlinedInput as MuiOutlinedInput,
  InputLabel as MuiInputLabel,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Grid as MuiGrid,
  Typography as MuiTypography,
  FormLabel as MuiFormLabel,
  Radio as MuiRadio,
  FormControlLabel as MuiFormControlLabel,
  Divider as MuiDivider,
  Button as MuiButton,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from "@material-ui/pickers";
import { Loop as MuiLoopIcon } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  RecruitmentStatusOptions,
  IrbStatusOptions,
  MultiSite,
  defaultFUDateFrom,
  defaultDateTo,
  defaultRecruitmentDateFrom,
  StudyDefaults,
  SummaryAccrual,
  CancerNonCancer,
  GET_STUDY_CONTACTS_URL,
  GET_STUDY_IRB_NUMBERS_URL,
  GET_STUDY_NICKNAMES_URL,
  GET_STUDY_PROTOCOL_NUMBER_URL,
  SelectHonestBrokerOption,
  STORAGE_NAMES,
  STORAGE_TYPE,
  RecruitmentStatus,
} from "app/shared/constants";
import { useUsers } from "common";
import { useFilters } from "app/services/filterService";
import { useStudies } from "app/services/studiesService";
import { setDefaultHB } from "app/shared/utils";
import PEChipContainer from "app/shared/UI/PEChipContainer";
import PEChip from "app/shared/UI/PEChip";
import AntSwitch from "app/shared/UI/AntSwitch";
import PERadioGroup from "app/shared/UI/PERadioGroup";
import { Autocomplete } from "app/UI/Autocomplete/Autocomplete";
import { AutocompleteOption } from "app/Utils/Autocomplete";
import { useBrowserStorage } from "app/services/StorageService/StorageHelper";
import { useStyles } from "app/study/StudyFiltersStyles";

const defaultStudyStatus = [
  "NOT_YET_RECRUITING",
  "RECRUITING",
  "ACTIVE_NOT_RECRUITING",
];

const getReStatus = (studyFilters) =>
  studyFilters.reStatus ? studyFilters.reStatus.split(",") : null;

const getCancer = (studyFilters) =>
  studyFilters.cancer ? studyFilters.cancer : "all";

const getProtocolSummaryAccrual = (studyFilters) =>
  studyFilters.protocolSummaryAccrual
    ? studyFilters.protocolSummaryAccrual
    : "all";

const getPtlStatus = (studyFilters) =>
  studyFilters.ptlStatus ? studyFilters.ptlStatus.split(",") : null;

const getProtocolMultisiteTrial = (studyFilters) =>
  studyFilters.protocolMultisiteTrial
    ? studyFilters.protocolMultisiteTrial
    : "all";

const StudyFilterForm = (props) => {
  const { studyFilters, setPagination, setStudyFilters } = useStudies();
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const {
    filterData: { studyDepartments, studyDivisions, honestBrokers },
  } = useFilters();
  const classes = useStyles();
  const { saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);

  // hooks, save initial value at state and setter
  const [irbNumber, setIrbNumber] = useState(studyFilters.irbNumber);
  const [nickname, setNickname] = useState(studyFilters.nickname);
  const [protocolNumber, setProtocolNumber] = useState(
    studyFilters.protocolNumber
  );
  const [reStatus, setReStatus] = useState(getReStatus(studyFilters));
  const [cancer, setCancer] = useState(getCancer(studyFilters));

  const [studyFiltersValue, setStudyFiltersValue] = useState({
    department: studyFilters.dept,
    division: studyFilters.division,
    honestBroker: studyFilters.hbId,
    honestBrokerStatus:
      studyFilters.honestBrokerStatus || SelectHonestBrokerOption.SELECT_HB,
  });

  useEffect(() => {
    if (honestBrokers?.values?.length > 0) {
      setStudyFiltersValue((prevFilters) => ({
        ...prevFilters,
        honestBroker: prevFilters.honestBroker
          ? prevFilters.honestBroker
          : setDefaultHB(honestBrokers.values, currentUser),
      }));
    }
  }, [honestBrokers?.values, currentUser]);

  const [protocolSummaryAccrual, setProtocolSummaryAccrual] = useState(
    getProtocolSummaryAccrual(studyFilters)
  );
  const [protocolMultisiteTrial, setProtocolMultisiteTrial] = useState(
    getProtocolMultisiteTrial(studyFilters)
  );
  const [ptlStatus, setPtlStatus] = useState(getPtlStatus(studyFilters));

  const [followUpDateFrom, setFollowUpDateFrom] = useState(
    studyFilters.fuDateFrom || null
  );
  const [minFUDateFrom] = useState(defaultFUDateFrom);
  const [maxFUDateFrom, setMaxFUDateFrom] = useState(defaultDateTo);
  const [followUpDateTo, setFollowUpDateTo] = useState(
    studyFilters.fuDateTo || null
  );
  const [minFUDateTo, setMinFUDateTo] = useState(defaultFUDateFrom);
  const [maxFUDateTo] = useState(defaultDateTo);

  const [suspended, setSuspended] = useState(studyFilters.suspended);
  const [selectedContact, setSelectedContact] = useState(
    studyFilters.contactStaffId
  );

  const [recruitmentStartDateFrom, setRecruitmentStartDateFrom] = useState(
    studyFilters.reStartDateFrom || null
  );
  const [minReStartDateFrom] = useState(defaultRecruitmentDateFrom);
  const [maxReStartDateFrom, setMaxReStartDateFrom] = useState(defaultDateTo);

  const [recruitmentStartDateTo, setRecruitmentStartDateTo] = useState(
    studyFilters.reStartDateTo || null
  );
  const [minReStartDateTo, setMinReStartDateTo] = useState(
    defaultRecruitmentDateFrom
  );
  const [maxReStartDateTo] = useState(defaultDateTo);

  const [recruitmentEndDateFrom, setRecruitmentEndDateFrom] = useState(
    studyFilters.reEndDateFrom || null
  );
  const [minReEndDateFrom] = useState(defaultRecruitmentDateFrom);
  const [maxReEndDateFrom, setMaxReEndDateFrom] = useState(defaultDateTo);

  const [recruitmentEndDateTo, setRecruitmentEndDateTo] = useState(
    studyFilters.reEndDateTo || null
  );
  const [minReEndDateTo, setMinReEndDateTo] = useState(
    defaultRecruitmentDateFrom
  );
  const [maxReEndDateTo] = useState(defaultDateTo);

  const setFilterPreference = (updateFilterField) => {
    const updatedFiltervalues = {
      ...studyFilters,
      ...updateFilterField,
    };

    saveItem(STORAGE_NAMES.PEP_STUDY_DASHBOARD_FILTERS, updatedFiltervalues);
    setPagination({
      page: StudyDefaults.STUDYDEFAULTPAGE,
      rowsPerPage: StudyDefaults.STUDYDEFAULTPAGESIZE,
    });
    setStudyFilters(updatedFiltervalues);
  };

  const handleResetStudyFilters = () => {
    // reset filters to default one

    setPagination({
      page: StudyDefaults.STUDYDEFAULTPAGE,
      rowsPerPage: StudyDefaults.STUDYDEFAULTPAGESIZE,
    });

    setStudyFiltersValue((prevFilters) => ({
      ...prevFilters,
      department: null,
      division: null,
      honestBroker: setDefaultHB(honestBrokers.values, currentUser),
      honestBrokerStatus: SelectHonestBrokerOption.SELECT_HB,
    }));

    setIrbNumber(null);
    setNickname(null);
    setProtocolNumber(null);
    setFollowUpDateFrom(null);
    setFollowUpDateTo(null);
    setReStatus(defaultStudyStatus);
    setSuspended(false);
    setSelectedContact(null);
    setCancer("all");
    setProtocolSummaryAccrual("all");
    setProtocolMultisiteTrial("all");
    setPtlStatus(null);
    setRecruitmentStartDateFrom(null);
    setRecruitmentStartDateTo(null);
    setRecruitmentEndDateFrom(null);
    setRecruitmentEndDateTo(null);

    const initialValue = {
      reStatus: StudyDefaults.STUDYDEFAULTSTATUS.join(","),
      suspended: StudyDefaults.STUDYDEFAULTSUSPENDED,
      hbId: setDefaultHB(honestBrokers?.values, currentUser),
    };

    saveItem(STORAGE_NAMES.PEP_STUDY_DASHBOARD_FILTERS, initialValue);
    setStudyFilters(initialValue);
  };

  const handleDisable = () =>
    ["ASSIGNED", "NOT_ASSIGNED", "UNKNOWN"].includes(
      studyFiltersValue.honestBrokerStatus
    );

  const selectHonestBrokerHandler = (newValue) => {
    // function to find the key based on the value
    const getKeyFromValue = (value) => {
      return Object.keys(SelectHonestBrokerOption).find(
        (key) => SelectHonestBrokerOption[key] === value // find the key that matches the value
      );
    };

    // update the state with the corresponding SelectHonestBrokerOption key,
    // or default to the "MuiSelect an Assigned Honest Broker" if not found
    setStudyFiltersValue((prevFilters) => ({
      ...prevFilters,
      honestBrokerStatus:
        newValue === SelectHonestBrokerOption.SELECT_HB
          ? SelectHonestBrokerOption.SELECT_HB
          : getKeyFromValue(newValue),
    }));

    // check if the newValue is one of the statuses that require clearing honestBroker
    if (
      [
        SelectHonestBrokerOption.ASSIGNED,
        SelectHonestBrokerOption.NOT_ASSIGNED,
        SelectHonestBrokerOption.UNKNOWN,
      ].includes(newValue)
    ) {
      setStudyFiltersValue((prevFilters) => ({
        ...prevFilters,
        honestBroker: null,
      }));
    } else {
      setStudyFiltersValue((prevFilters) => ({
        ...prevFilters,
        honestBroker: setDefaultHB(honestBrokers?.values, currentUser),
      }));
    }

    // FOR UNINITIATED
    if (
      [
        SelectHonestBrokerOption.NOT_ASSIGNED,
        SelectHonestBrokerOption.UNKNOWN,
      ].includes(newValue)
    ) {
      setReStatus((prevReStatus) => {
        // check if UNINITIATED is already in the statuss
        if (!prevReStatus.includes("UNINITIATED")) {
          return [...prevReStatus, "UNINITIATED"];
        }
        return prevReStatus;
      });
    } else {
      // if the selection is changed to something else, this will remove UNINITIATED if present
      setReStatus((prevReStatus) =>
        prevReStatus.filter((status) => status !== "UNINITIATED")
      );
    }

    const finalRestatus = reStatus;

    const honestBrokerValue = (newValue) => {
      return Object.keys(SelectHonestBrokerOption).filter(
        (key) => SelectHonestBrokerOption[key] === newValue
      );
    };

    const filterObject = {
      honestBrokerStatus: honestBrokerValue(newValue)[0] || null,
      hbId: null,
    };

    if (
      newValue === SelectHonestBrokerOption.UNKNOWN ||
      newValue === SelectHonestBrokerOption.NOT_ASSIGNED
    ) {
      if (!reStatus.includes(RecruitmentStatus.UNINITIATED)) {
        finalRestatus.push(RecruitmentStatus.UNINITIATED);

        filterObject.reStatus = finalRestatus.join(",");
      }
    } else if (
      newValue === SelectHonestBrokerOption.SELECT_HB ||
      newValue === SelectHonestBrokerOption.ASSIGNED
    ) {
      if (reStatus.includes(RecruitmentStatus.UNINITIATED)) {
        finalRestatus.forEach((status, index) => {
          if (status === RecruitmentStatus.UNINITIATED) {
            finalRestatus.splice(index, 1);
          }
        });
      }
      filterObject.reStatus = finalRestatus.join(",");
    }

    if (newValue === SelectHonestBrokerOption.SELECT_HB) {
      filterObject.honestBrokerStatus = null;
      filterObject.hbId = setDefaultHB(honestBrokers?.values, currentUser);
    }

    setFilterPreference(filterObject);
  };

  return (
    <MuiGrid container>
      <MuiGrid className={classes.customHeaderPadding} item xs={12}>
        <MuiTypography variant="h6" noWrap>
          {t("formLabel.studyFilterTitle")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid item xs={12}>
        <MuiDivider />
      </MuiGrid>
      <MuiGrid container item xs={12} justifyContent="flex-end">
        <div className={classes.wrapper}>
          <MuiButton
            startIcon={<MuiLoopIcon />}
            onClick={handleResetStudyFilters}
          >
            {t("formLabel.buttonResetFilter")}
          </MuiButton>
        </div>
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="irb-number"
          getOptionLabel={(option) => option.displayText}
          label={t("formLabel.irbNumberFilterLabel")}
          value={irbNumber ?? {}}
          onChange={(evt, value) => {
            setIrbNumber(value);
            setFilterPreference({ irbNumber: value ?? null });
          }}
          renderOption={(option, { inputValue }) => {
            return (
              <AutocompleteOption option={option} inputValue={inputValue} />
            );
          }}
          urlPrefix={GET_STUDY_IRB_NUMBERS_URL}
        />
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="study-protocolNumber"
          getOptionLabel={(option) => option.displayText}
          label={t("formLabel.protocolNumber")}
          value={protocolNumber}
          onChange={(evt, value) => {
            setProtocolNumber(value);
            setFilterPreference({ protocolNumber: value ?? null });
          }}
          renderOption={(option, { inputValue }) => {
            return (
              <AutocompleteOption option={option} inputValue={inputValue} />
            );
          }}
          urlPrefix={GET_STUDY_PROTOCOL_NUMBER_URL}
        />
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="selection-HonestBroker"
          key="selectionHonestBroker"
          label={t("formLabel.selectHonestBroker")}
          variant="outlined"
          margin="dense"
          options={Object.values(SelectHonestBrokerOption)}
          getOptionLabel={(option) => option}
          value={
            studyFiltersValue.honestBrokerStatus !==
            SelectHonestBrokerOption.SELECT_HB
              ? SelectHonestBrokerOption[studyFiltersValue.honestBrokerStatus]
              : SelectHonestBrokerOption.SELECT_HB
          }
          onChange={(evt, newValue) => selectHonestBrokerHandler(newValue)}
          clientSide={true}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="assigned-HonestBroker"
          key="assignedHonestBroker"
          label={t("formLabel.assignedHonestBroker")}
          variant="outlined"
          margin="dense"
          options={honestBrokers?.values || []}
          getOptionLabel={(option) => option.displayName || ""}
          value={
            (studyFiltersValue.honestBrokerStatus ===
              SelectHonestBrokerOption.SELECT_HB &&
              honestBrokers?.values?.find(
                (option) => option.id === studyFiltersValue.honestBroker
              )) ||
            null
          }
          onChange={(evt, newValue) => {
            setStudyFiltersValue((prevFilters) => ({
              ...prevFilters,
              honestBroker: newValue ? newValue.id : "",
            }));

            studyFiltersValue.honestBrokerStatus ===
              SelectHonestBrokerOption.SELECT_HB &&
              setFilterPreference({ hbId: newValue ? newValue.id : null });
          }}
          clientSide={true}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={handleDisable()}
        />
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <MuiFormControl fullWidth variant="outlined">
          <MuiInputLabel htmlFor="recruitment-status" shrink={true}>
            {t("formLabel.recruitmentStatusFilterLabel")}
          </MuiInputLabel>
          <MuiSelect
            value={reStatus || []}
            onChange={(e) => {
              setReStatus(e.target.value);
              setFilterPreference({
                reStatus: e.target.value.join(",") || null,
              });
            }}
            multiple
            input={
              <MuiOutlinedInput
                id="recruitment-status"
                name="recruitment-status"
                label={t("formLabel.recruitmentStatusFilterLabel")}
                margin="dense"
                notched
              />
            }
            renderValue={(selected) => (
              <PEChipContainer>
                {selected.map((value) => (
                  <PEChip key={value} label={t("recruitmentStatus." + value)} />
                ))}
              </PEChipContainer>
            )}
          >
            {RecruitmentStatusOptions.map((item) => (
              <MuiMenuItem key={item} value={item}>
                {t("recruitmentStatus." + item)}
              </MuiMenuItem>
            ))}
          </MuiSelect>
        </MuiFormControl>
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <MuiTypography component="div">
          <MuiFormLabel component="legend">
            {t("formLabel.suspended")}
          </MuiFormLabel>
          <MuiGrid
            id="suspendedSwitch"
            component="label"
            container
            alignItems="center"
            spacing={1}
          >
            <MuiGrid item>{t("formLabel.switchNo")}</MuiGrid>
            <MuiGrid item>
              <AntSwitch
                checked={suspended}
                onChange={(evt) => {
                  setSuspended(evt.target.checked);
                  setFilterPreference({
                    suspended: evt.target.checked || null,
                  });
                }}
                value="suspended"
              />
            </MuiGrid>
            <MuiGrid item>{t("formLabel.switchYes")}</MuiGrid>
          </MuiGrid>
        </MuiTypography>
      </MuiGrid>
      <MuiGrid
        container
        className={classes.customAutocompletePadding}
        item
        xs={12}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiGrid item xs>
            <MuiKeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label={t("formLabel.followUpDateFrom")}
              format="MM/dd/yyyy"
              margin="dense"
              id="follow-up-date-start"
              value={followUpDateFrom}
              minDate={minFUDateFrom}
              maxDate={maxFUDateFrom}
              onChange={(date) => {
                setFollowUpDateFrom(date);
                // change Follow-up-date-to minFUDate to make sure date-to is always after date-from
                setMinFUDateTo(date);

                setFilterPreference({
                  fuDateFrom: date
                    ? date
                    : followUpDateTo
                    ? defaultFUDateFrom
                    : null,
                  fuDateTo: date
                    ? followUpDateTo
                      ? followUpDateTo
                      : defaultDateTo
                    : followUpDateTo
                    ? followUpDateTo
                    : null,
                });
              }}
            />
          </MuiGrid>
          <MuiGrid container justifyContent="center" item xs={1}>
            <span className={classes.datePicker}>{" - "}</span>
          </MuiGrid>
          <MuiGrid item xs>
            <MuiKeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label={t("formLabel.followUpDateTo")}
              format="MM/dd/yyyy"
              margin="dense"
              id="follow-up-date-end"
              value={followUpDateTo}
              minDate={minFUDateTo}
              maxDate={maxFUDateTo}
              onChange={(date) => {
                setFollowUpDateTo(date);
                // change Follow-up-date-from maxFUDate to make sure date-from is always before date-to
                setMaxFUDateFrom(date);
                setFilterPreference({
                  fuDateTo: date
                    ? date
                    : followUpDateFrom
                    ? defaultDateTo
                    : null,
                  fuDateFrom: date
                    ? followUpDateFrom
                      ? followUpDateFrom
                      : minFUDateFrom
                    : followUpDateFrom
                    ? followUpDateFrom
                    : null,
                });
              }}
            />
          </MuiGrid>
        </MuiPickersUtilsProvider>
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="study-nickname"
          getOptionLabel={(option) => option.displayText}
          label={t("formLabel.studyNickname")}
          value={nickname}
          onChange={(evt, value) => {
            setNickname(value);
            setFilterPreference({ nickname: value || null });
          }}
          renderOption={(option, { inputValue }) => {
            return (
              <AutocompleteOption option={option} inputValue={inputValue} />
            );
          }}
          urlPrefix={GET_STUDY_NICKNAMES_URL}
        />
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="contact-name"
          getOptionLabel={(option) => option.displayText ?? ""}
          label={t("formLabel.contactName")}
          value={selectedContact}
          onChange={(evt, value) => {
            setSelectedContact(value);
            setFilterPreference({ contactStaffId: value || null });
          }}
          renderOption={(option, { inputValue }) => {
            return (
              <AutocompleteOption option={option} inputValue={inputValue} />
            );
          }}
          urlPrefix={GET_STUDY_CONTACTS_URL}
        />
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="study-dept"
          key="study-department"
          options={studyDepartments?.values || []}
          value={studyFiltersValue.department ?? null}
          getOptionLabel={(option) => option}
          clientSide={true}
          onChange={(evt, newValue) => {
            setStudyFiltersValue((prevFilters) => ({
              ...prevFilters,
              department: newValue,
            }));

            setFilterPreference({ dept: newValue || null });
          }}
          label={t("formLabel.department")}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          margin="dense"
        />
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="study-division"
          key="studyDivision"
          options={
            studyDivisions?.values.filter((option) => option !== null) || []
          }
          getOptionLabel={(option) => option}
          value={studyFiltersValue.division ?? null}
          clientSide={true}
          onChange={(evt, newValue) => {
            setStudyFiltersValue((prevFilters) => ({
              ...prevFilters,
              division: newValue,
            }));

            setFilterPreference({ division: newValue || null });
          }}
          label={t("formLabel.divisionFilterLabel")}
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <MuiFormControl fullWidth component="fieldset">
          <MuiFormLabel component="legend">
            {t("formLabel.cancerCenterFilterLabel")}
          </MuiFormLabel>
          <PERadioGroup
            name="cancer-non-cancer"
            value={cancer}
            onChange={(e) => {
              setCancer(e.target.value);
              setFilterPreference({
                cancer: e.target.value || null,
              });
            }}
          >
            <MuiFormControlLabel
              value={CancerNonCancer.All}
              control={<MuiRadio />}
              label={t("formLabel.cancerCenterAll")}
            />
            <MuiFormControlLabel
              value={CancerNonCancer.Cancer}
              control={<MuiRadio />}
              label={t("formLabel.cancerCenterCancer")}
            />
            <MuiFormControlLabel
              value={CancerNonCancer.NonCancer}
              control={<MuiRadio />}
              label={t("formLabel.cancerCenterNon")}
            />
          </PERadioGroup>
        </MuiFormControl>
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <MuiFormControl fullWidth component="fieldset">
          <MuiFormLabel component="legend">
            {t("formLabel.multiSite")}
          </MuiFormLabel>
          <PERadioGroup
            name="multi-site"
            value={protocolMultisiteTrial}
            onChange={(e) => {
              setProtocolMultisiteTrial(e.target.value);
              setFilterPreference({
                protocolMultisiteTrial: e.target.value || null,
              });
            }}
          >
            <MuiFormControlLabel
              value="all"
              control={<MuiRadio />}
              label={t("formLabel.multiSiteAll")}
            />
            <MuiFormControlLabel
              value={MultiSite.MultiSiteFalse}
              control={<MuiRadio />}
              label={t("formLabel.multiSiteStanford")}
            />
            <MuiFormControlLabel
              value={MultiSite.MultiSiteTrue}
              control={<MuiRadio />}
              label={t("formLabel.multiSite")}
            />
          </PERadioGroup>
        </MuiFormControl>
      </MuiGrid>
      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <MuiFormControl fullWidth variant="outlined">
          <MuiInputLabel htmlFor="irb-status" shrink={true} variant="outlined">
            {t("formLabel.irbStatus")}
          </MuiInputLabel>
          <MuiSelect
            value={ptlStatus || []}
            onChange={(e) => {
              setPtlStatus(e.target.value);
              setFilterPreference({
                ptlStatus: e.target.value.join(",") || null,
              });
            }}
            multiple
            input={
              <MuiOutlinedInput
                id="irb-status"
                label={t("formLabel.irbStatus")}
                labelWidth={1}
                margin="dense"
                placeholder={t("formLabel.irbStatus")}
                notched
              />
            }
            renderValue={(selected) => (
              <PEChipContainer>
                {selected.map((value) => (
                  <PEChip key={value} label={t("irbStatus." + value)} />
                ))}
              </PEChipContainer>
            )}
          >
            {IrbStatusOptions.map((item) => (
              <MuiMenuItem key={item} value={item}>
                {t("irbStatus." + item)}
              </MuiMenuItem>
            ))}
          </MuiSelect>
        </MuiFormControl>
      </MuiGrid>

      <MuiGrid className={classes.customAutocompletePadding} item xs={12}>
        <MuiFormControl fullWidth component="fieldset">
          <MuiFormLabel component="legend">
            {t("formLabel.summaryAccrual")}
          </MuiFormLabel>
          <PERadioGroup
            name="protocolSummaryAccrual"
            value={protocolSummaryAccrual}
            onChange={(e) => {
              setProtocolSummaryAccrual(e.target.value);
              setFilterPreference({
                protocolSummaryAccrual: e.target.value || null,
              });
            }}
          >
            <MuiFormControlLabel
              value="all"
              control={<MuiRadio />}
              label={t("formLabel.protocolSummaryAccrualAll")}
            />
            <MuiFormControlLabel
              value={SummaryAccrual.SummaryAccrualTrue}
              control={<MuiRadio />}
              label={t("formLabel.protocolSummaryAccrualYes")}
            />
            <MuiFormControlLabel
              value={SummaryAccrual.SummaryAccrualFalse}
              control={<MuiRadio />}
              label={t("formLabel.protocolSummaryAccrualNo")}
            />
          </PERadioGroup>
        </MuiFormControl>
      </MuiGrid>
      <MuiGrid
        container
        className={classes.customAutocompletePadding}
        item
        xs={12}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiGrid item xs>
            <MuiKeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.labelWrapper}
              label={t("formLabel.recruitmentStartDateFrom")}
              format="MM/dd/yyyy"
              margin="dense"
              id="recruitment-start-date-from"
              value={recruitmentStartDateFrom}
              minDate={minReStartDateFrom}
              maxDate={maxReStartDateFrom}
              onChange={(date) => {
                setRecruitmentStartDateFrom(date);
                // use the date as date-to's minDate
                setMinReStartDateTo(date);

                setFilterPreference({
                  reStartDateFrom: date
                    ? date
                    : recruitmentStartDateTo
                    ? defaultRecruitmentDateFrom
                    : null,
                  reStartDateTo: date
                    ? recruitmentStartDateTo
                      ? recruitmentStartDateTo
                      : defaultDateTo
                    : recruitmentStartDateTo
                    ? recruitmentStartDateTo
                    : null,
                });
              }}
            />
          </MuiGrid>
          <MuiGrid container justifyContent="center" item xs={1}>
            <span className={classes.datePicker}>{" - "}</span>
          </MuiGrid>
          <MuiGrid item xs>
            <MuiKeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.labelWrapper}
              label={t("formLabel.recruitmentStartDateTo")}
              format="MM/dd/yyyy"
              margin="dense"
              id="recruitment-start-date-to"
              value={recruitmentStartDateTo}
              minDate={minReStartDateTo}
              maxDate={maxReStartDateTo}
              onChange={(date) => {
                setRecruitmentStartDateTo(date);
                // use the date to be maxDate of recruitmentStartDate date-from
                setMaxReStartDateFrom(date);

                setFilterPreference({
                  reStartDateTo: date
                    ? date
                    : recruitmentStartDateFrom
                    ? defaultDateTo
                    : null,

                  reStartDateFrom: date
                    ? recruitmentStartDateFrom
                      ? recruitmentStartDateFrom
                      : defaultRecruitmentDateFrom
                    : recruitmentStartDateFrom
                    ? recruitmentStartDateFrom
                    : null,
                });
              }}
            />
          </MuiGrid>
        </MuiPickersUtilsProvider>
      </MuiGrid>
      <MuiGrid
        container
        className={classes.customAutocompletePadding}
        item
        xs={12}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiGrid item xs>
            <MuiKeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.labelWrapper}
              label={t("formLabel.recruitmentEndDateFrom")}
              format="MM/dd/yyyy"
              margin="dense"
              id="recruitment-end-date-from"
              value={recruitmentEndDateFrom}
              minDate={minReEndDateFrom}
              maxDate={maxReEndDateFrom}
              onChange={(date) => {
                setRecruitmentEndDateFrom(date);
                // use the date to be minDate of recruitmentStartDate date-to
                setMinReEndDateTo(date);

                setFilterPreference({
                  reEndDateFrom: date
                    ? date
                    : recruitmentEndDateTo
                    ? defaultRecruitmentDateFrom
                    : null,
                  reEndDateTo: date
                    ? recruitmentEndDateTo
                      ? recruitmentEndDateTo
                      : defaultDateTo
                    : recruitmentEndDateTo
                    ? recruitmentEndDateTo
                    : null,
                });
              }}
            />
          </MuiGrid>
          <MuiGrid container justifyContent="center" item xs={1}>
            <span className={classes.datePicker}>{" - "}</span>
          </MuiGrid>
          <MuiGrid item xs>
            <MuiKeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label={t("formLabel.recruitmentEndDateTo")}
              format="MM/dd/yyyy"
              margin="dense"
              id="recruitment-end-date-to"
              value={recruitmentEndDateTo}
              minDate={minReEndDateTo}
              maxDate={maxReEndDateTo}
              onChange={(date) => {
                setRecruitmentEndDateTo(date);
                setMaxReEndDateFrom(date);

                setFilterPreference({
                  reEndDateTo: date
                    ? date
                    : recruitmentEndDateFrom
                    ? defaultDateTo
                    : null,
                  reEndDateFrom: date
                    ? recruitmentEndDateFrom
                      ? recruitmentEndDateFrom
                      : defaultRecruitmentDateFrom
                    : recruitmentEndDateFrom
                    ? recruitmentEndDateFrom
                    : null,
                });
              }}
            />
          </MuiGrid>
        </MuiPickersUtilsProvider>
      </MuiGrid>
      <MuiGrid container item xs={12} justifyContent="flex-end">
        <div className={classes.wrapper}>
          <MuiButton
            startIcon={<MuiLoopIcon />}
            onClick={handleResetStudyFilters}
          >
            {t("formLabel.buttonResetFilter")}
          </MuiButton>
        </div>
      </MuiGrid>
    </MuiGrid>
  );
};

export default StudyFilterForm;
