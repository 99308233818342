import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Link as MuiLink,
} from "@material-ui/core";
import { useStyles } from "app/reports/TableauReportStyles";
import PEPage from "app/shared/UI/PEPage";
import { handleTableauUrls } from "app/shared/utils";

const PortfolioReports = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const ReportContent = () => {
    return (
      <div className={classes.paper}>
        <MuiGrid container spacing={2}>
          <MuiGrid item>
            <MuiLink
              href={`${handleTableauUrls()}/OverviewofStudiesWaves`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MuiTypography variant="h5" noWrap>
                {t("TableauReport.overviewStudiesReport")}
              </MuiTypography>
            </MuiLink>
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiTypography className={classes.descContainer}>
              {t("TableauReport.overviewOfStudiesReportDesc")}
            </MuiTypography>
          </MuiGrid>

          <MuiGrid item>
            <MuiLink
              href={`${handleTableauUrls()}/StudieswithHighestReturn`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MuiTypography variant="h5" noWrap>
                {t("TableauReport.highestReturnStudiesReport")}
              </MuiTypography>
            </MuiLink>
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiTypography className={classes.descContainer}>
              {t("TableauReport.highestReturnStudiesReportDesc")}
            </MuiTypography>
          </MuiGrid>

          <MuiGrid item>
            <MuiLink
              href={`${handleTableauUrls()}/StudySummaryDashboard`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MuiTypography variant="h5" noWrap>
                {t("TableauReport.studySummaryDashboardReport")}
              </MuiTypography>
            </MuiLink>
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MuiTypography className={classes.descContainer}>
              {t("TableauReport.studySummaryDashboardReportDesc")}
            </MuiTypography>
          </MuiGrid>
        </MuiGrid>
      </div>
    );
  };

  return (
    <PEPage
      hasDrawer={false}
      pageTitle={t("TableauReport.portfolioPageTitle")}
      hasBackButton={false}
      contentComponent={ReportContent}
      {...props}
    />
  );
};

export default PortfolioReports;
