import { useState } from "react";
import { Grid as MuiGrid } from "@material-ui/core";
import { useStudies } from "app/services/studiesService";
import { mapStudiesToDisplayed } from "app/shared/utils";
import StudyTable from "app/study/StudyTable";

const StudyListingPageContent = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const { studies } = useStudies();

  const handleSelectStudy = (rows) => {
    setSelectedRows(rows);
  };

  const mappedStudies = mapStudiesToDisplayed(studies);

  return (
    <MuiGrid container>
      <MuiGrid item xs={12}>
        <StudyTable
          selectStudyhandler={handleSelectStudy}
          selectedRows={selectedRows}
          studies={mappedStudies}
          highlightRow={
            props.history.location.highlightRow >= 0
              ? props.history.location.highlightRow
              : -1
          }
        />
      </MuiGrid>
    </MuiGrid>
  );
};

export default StudyListingPageContent;
