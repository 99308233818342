import axios from "axios";
import { formatISO } from "date-fns";
import { removeEmptyParams } from "app/shared/utils";

export const getAllLookupProspects = async (
  setLookupProspectList,
  setTotalCount,
  setAlert,
  filters,
  pagination,
  sort
) => {
  try {
    const { page, rowsPerPage } = pagination;
    const { orderBy, order } = sort;
    const prospectParams = {
      firstName: filters.firstName,
      lastName: filters.lastName,
      mrn: filters.mrn,
      phone: filters.phone,
      email: filters.email,
      birthDate: filters.birthDate && formatISO(new Date(filters.birthDate)),
      isOptedOut: filters.isOptedOut,
      isHavingConcern: filters.isHavingConcern,
      page: page,
      size: rowsPerPage,
      sort: `${orderBy},${order}`,
    };
    removeEmptyParams(prospectParams);

    const request = {
      url: `/prospects`,
      params: prospectParams,
    };

    const response = await axios(request);

    if (response.data && response.data.totalCount > 0) {
      setLookupProspectList(response.data.values);
      setTotalCount(response.data.totalCount);
    } else {
      setLookupProspectList([]);
      setTotalCount(0);
    }
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getProspectByMrn = async (
  mrn,
  setProspectData,
  setAlert,
  setOptedStatus
) => {
  try {
    const request = {
      url: `/prospects/${mrn}`,
    };

    const response = await axios(request);

    if (response && response.data) {
      setProspectData(response.data);
      setOptedStatus(response.data.optOut ? true : false);
    } else {
      setProspectData({});
    }
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const editProspectById = async (
  mrn,
  prospectData,
  setAlert,
  setProspectData,
  history
) => {
  try {
    const request = {
      method: "PATCH",
      url: `/prospects/${mrn}`,
      data: prospectData,
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    };

    const response = await axios(request);
    setProspectData(response.data);
    history.push("/prospects/lookup");
  } catch (error) {
    setAlert("error", error.response.data.message);
  }
};

export const getProspectOutreachHistory = async (
  mrn,
  setOutreachHistory,
  outreachHistoryFilters,
  setOutreachHistoryTotalCount,
  setAlert
) => {
  try {
    const request = {
      url: `/prospects/${mrn}/outreaches`,
      params: {
        page: outreachHistoryFilters.page,
        size: outreachHistoryFilters.size,
        sort: outreachHistoryFilters.sort,
      },
    };

    const response = await axios(request);
    setOutreachHistoryTotalCount(response.data.totalCount || 0);
    setOutreachHistory(response.data.values || []);
  } catch (error) {
    setOutreachHistory([]);
    setOutreachHistoryTotalCount(0);
    setAlert("error", error.message);
  }
};

export const getProspectCohortHistory = async (
  mrn,
  setCohortHistory,
  cohortHistoryFilters,
  setAlert,
  setCohortHistoryTotalCount
) => {
  try {
    const request = {
      url: `/prospects/${mrn}/imports`,
      params: {
        page: cohortHistoryFilters.page,
        size: cohortHistoryFilters.size,
        sort: cohortHistoryFilters.sort,
      },
    };

    const response = await axios(request);

    setCohortHistory(response.data.values);
    setCohortHistoryTotalCount(response.data.totalCount);
  } catch (error) {
    setCohortHistory([]);
    setCohortHistoryTotalCount(0);
    setAlert("error", error.message);
  }
};
