import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper as MuiPaper,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  Grid as MuiGrid,
  TablePagination as MuiTablePagination,
  TableSortLabel as MuiTableSortLabel,
  Link as MuiLink,
} from "@material-ui/core";
import { useAlerts } from "common";
import { getProspectOutreachHistory } from "app/services/prospectServices";
import {
  PepOnStudy,
  ProspectOutreachHistoryDefaults,
  URLs,
} from "app/shared/constants";
import PEExport from "app/shared/UI/PEExport";

const ProspectDetailsOutreachHistory = (props) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const { mrn } = props;
  const [outreachHistory, setOutreachHistory] = useState([]);
  const [outreachHistoryFilters, setOutreachHistoryFilters] = useState({
    sort: `${ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_ORDERBY},${ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_ORDER}`,
    page: ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_PAGE,
    size: ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_PAGESIZE,
  });
  const [sort, setSort] = useState({
    order: ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_ORDER,
    orderBy: ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_ORDERBY,
  });
  const [page, setPage] = useState(
    outreachHistoryFilters.page ||
      ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_PAGE
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    outreachHistoryFilters.size ||
      ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_PAGESIZE
  );
  const [outreachHistoryTotalCount, setOutreachHistoryTotalCount] = useState(0);

  const createSortHandler = (property) => (event) =>
    handleRequestSort(event, property);

  const handleRequestSort = (event, property) => {
    const direction =
      sort.orderBy === property && sort.order === "asc" ? "desc" : "asc";
    setSort({
      orderBy: property,
      order: direction,
    });

    setOutreachHistoryFilters({
      ...outreachHistoryFilters,
      sort: `${property},${direction}`,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOutreachHistoryFilters({
      ...outreachHistoryFilters,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_PAGE);
    setRowsPerPage(parseInt(event.target.value, 10));
    setOutreachHistoryFilters({
      ...outreachHistoryFilters,
      page: ProspectOutreachHistoryDefaults.OUTREACH_HISTORY_DEFAULT_PAGE,
      size: parseInt(event.target.value, 10),
    });
  };

  useEffect(() => {
    getProspectOutreachHistory(
      mrn,
      setOutreachHistory,
      outreachHistoryFilters,
      setOutreachHistoryTotalCount,
      setAlert
    );
  }, [mrn, setAlert, outreachHistoryFilters]);

  const outreachHistoryHeadCells = [
    { id: "irbNumber", label: "irb", sort: true },
    { id: "waveName", label: "fileName", sort: true },
    { id: "channel", label: "outreachChannel", sort: true },
    { id: "status", label: "outreachStatus", sort: true },
    { id: "conductedDate", label: "outreachDate", sort: true },
    { id: "failureReason", label: "failureReason" },
    { id: "responseStatus", label: "responseStatus", sort: true },
    { id: "pepOnStudy", label: "pepOnStudy", sort: true },
  ];

  return (
    <MuiGrid container>
      <MuiGrid item xs={12}>
        <MuiPaper>
          <MuiTableContainer>
            <MuiTable stickyHeader>
              <MuiTableHead>
                {outreachHistoryHeadCells.map((headCell) => (
                  <MuiTableCell
                    key={headCell.id}
                    align={headCell.align ? headCell.align : ""}
                  >
                    {headCell.sort ? (
                      <MuiTableSortLabel
                        active={sort.orderBy === headCell.id}
                        direction={
                          sort.orderBy === headCell.id ? sort.order : "asc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {t(`ProspectDetailsOutreachHistory.${headCell.label}`)}
                      </MuiTableSortLabel>
                    ) : (
                      t(`ProspectDetailsOutreachHistory.${headCell.label}`)
                    )}
                  </MuiTableCell>
                ))}
              </MuiTableHead>
              <MuiTableBody>
                {!outreachHistory.length && (
                  <MuiTableRow>
                    <MuiTableCell colSpan="8" align="center">
                      {t("ProspectDetailsOutreachHistory.emptyTableRow")}
                    </MuiTableCell>
                  </MuiTableRow>
                )}
                {outreachHistory.map((row, idx) => (
                  <MuiTableRow key={idx}>
                    <MuiTableCell>
                      <MuiLink
                        href={`${window.location.origin}/outreach/${row.studyId}/conduct`}
                        target="_blank"
                        rel="noopener noreferrer"
                        replace
                      >
                        {row.irbNumber}
                      </MuiLink>
                    </MuiTableCell>
                    <MuiTableCell>
                      <PEExport
                        displayName={row.waveName}
                        // TODO: use wavename_<filename>
                        downloadName={row.waveName + ".csv"}
                        exportURL={`${URLs.GET_OUTREACH_URL_BASE}/${row.outreachId}/file`}
                      />
                    </MuiTableCell>
                    <MuiTableCell>{row.channel}</MuiTableCell>
                    <MuiTableCell>
                      {t(`ProspectDetailsOutreachHistory.${row.status}`)}
                    </MuiTableCell>
                    <MuiTableCell>
                      {new Date(row.conductedDate).toLocaleString()}
                    </MuiTableCell>
                    <MuiTableCell width="200">{row.failureReason}</MuiTableCell>
                    <MuiTableCell>
                      {t(
                        `ProspectDetailsOutreachHistory.${row.responseStatus}`
                      )}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.pepOnStudy ? PepOnStudy.YES : PepOnStudy.NO}
                    </MuiTableCell>
                  </MuiTableRow>
                ))}
              </MuiTableBody>
            </MuiTable>
          </MuiTableContainer>
          <MuiTablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={outreachHistoryTotalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </MuiPaper>
      </MuiGrid>
    </MuiGrid>
  );
};

export default ProspectDetailsOutreachHistory;
