import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TablePagination as MuiTablePagination,
  TableRow as MuiTableRow,
  Checkbox as MuiCheckbox,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  TableHead as MuiTableHead,
  TableContainer as MuiTableContainer,
  Paper as MuiPaper,
  TableSortLabel as MuiTableSortLabel,
  Grid as MuiGrid,
  Link as MuiLink,
  Typography as MuiTypography,
} from "@material-ui/core";
import {
  Edit as MuiEditIcon,
  Warning as MuiWarningIcon,
  RecordVoiceOver as MuiRecordVoiceOverIcon,
  CloudUpload as MuiCloudUploadIcon,
  ErrorOutline as MuiErrorOutlineIcon,
  Assessment as MuiAssessmentIcon,
  TableChart as MuiTableChartIcon,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import PEErrorDialog from "app/shared/UI/PEErrorDialog";
import PEMail from "app/shared/UI/PEMail";
import OutreachFailureSummary from "app/shared/UI/OutreachFailureSummary";
import { useStudies } from "app/services/studiesService";
import { RecruitmentStatus, SummaryAccrual } from "app/shared/constants";
import { useStyles } from "app/study/StudyTableStyles";
import { handleTableauUrls } from "app/shared/utils";
import { HONEST_BROKER_ASSIGNMENT_STATUS } from "app/shared/constants";

const errorSummaryContent = {
  primaryContent: "condition",
  secondaryContent: "recommendation",
};

const headCells = [
  {
    id: "irbNumber",
    label: ["head_label_irb"],
    tooltip: "head_tooltip_irb",
    maxWidth: 80,
    isSort: true,
  },
  {
    id: "protocolNumber",
    label: ["head_label_protocolNumber"],
    isSort: true,
  },
  {
    id: "outreachStatus",
    label: ["head_label_outreach_status"],
    isSort: false,
  },
  {
    id: "protocolStatus",
    label: ["head_label_irb_status"],
    isSort: true,
  },
  {
    id: "protocolSummaryAccrual",
    label: ["head_label_protocol_summary_accrual"],
    isSort: true,
  },
  {
    id: "nickname",
    label: ["head_label_nickname"],
    isSort: true,
  },
  {
    id: "recruitmentStatus",
    label: ["head_label_recruitment", "head_label_status"],
    isSort: true,
  },
  {
    id: "leadCrcName",
    label: ["head_label_lead_crc", "head_label_pi"],
    isSort: false,
  },
  {
    id: "protocolDeptName,division",
    label: ["head_label_department", "head_label_division"],
    isSort: true,
  },
  {
    id: "riskCount",
    label: ["head_label_risk"],
    tooltip: "head_tooltip_risk",
    isSort: false,
  },

  {
    id: "recruited",
    label: ["head_label_recruited"],
    tooltip: "head_tooltip_recruited",
    isSort: false,
  },
  {
    label: ["head_label_time_elapsed"],
    tooltip: "head_tooltip_time_elapsed",
    id: "timeElapsed",
    isSort: false,
  },
  {
    id: "recruitmentEndDate",
    label: ["head_label_study_end_date"],
    isSort: true,
  },
  {
    id: "followUpDate",
    label: ["head_label_follow_up_date"],
    isSort: true,
  },
  {
    id: "note",
    label: ["head_label_note"],
    isSort: true,
  },
  {
    id: "actions",
    label: ["head_label_actions"],
    isSort: false,
  },
];

const displayHeadCell = (headCell, order, orderBy, t, createSortHandler) => {
  return headCell.isSort ? (
    <MuiTableSortLabel
      active={orderBy === headCell.id}
      direction={order}
      onClick={(e) => {
        createSortHandler(headCell.id);
      }}
    >
      {headCell.label.length > 1 ? (
        <MuiGrid container>
          <MuiGrid item xs={12}>
            <span>{t("studyTable." + headCell.label[0])}</span>
          </MuiGrid>
          <MuiGrid item xs={12}>
            <span>{t("studyTable." + headCell.label[1])}</span>
          </MuiGrid>
        </MuiGrid>
      ) : (
        t("studyTable." + headCell.label[0])
      )}
    </MuiTableSortLabel>
  ) : headCell.label.length > 1 ? (
    <MuiGrid container>
      <MuiGrid item xs={12}>
        <span>{t("studyTable." + headCell.label[0])}</span>
      </MuiGrid>
      <MuiGrid item xs={12}>
        <span>{t("studyTable." + headCell.label[1])}</span>
      </MuiGrid>
    </MuiGrid>
  ) : (
    t("studyTable." + headCell.label[0])
  );
};

const displayHeadCellWithToolTip = (
  headCell,
  order,
  orderBy,
  t,
  createSortHandler,
  styles
) => {
  return (
    <MuiTooltip
      title={t("studyTable." + headCell.tooltip)}
      placement="top"
      arrow
      classes={{
        tooltip: styles.lightTooltip,
        arrow: styles.lightTooltipArrow,
      }}
    >
      <div>
        {displayHeadCell(headCell, order, orderBy, t, createSortHandler)}
      </div>
    </MuiTooltip>
  );
};

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    styles,
  } = props;
  const { t } = useTranslation();
  const createSortHandler = (property, event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell padding="checkbox">
          <MuiTooltip title={t("studyTable.head_tooltip_select_all")} arrow>
            <MuiCheckbox
              className={styles.headerCheckbox}
              disableRipple={true}
              checked={numSelected === rowCount && rowCount > 0}
              onChange={onSelectAllClick}
            />
          </MuiTooltip>
        </MuiTableCell>
        {headCells.map((headCell) => (
          <MuiTableCell
            key={headCell.id}
            headcell={headCell}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.tooltip
              ? displayHeadCellWithToolTip(
                  headCell,
                  order,
                  orderBy,
                  t,
                  createSortHandler,
                  styles
                )
              : displayHeadCell(headCell, order, orderBy, t, createSortHandler)}
          </MuiTableCell>
        ))}
      </MuiTableRow>
    </MuiTableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const StudyTable = (props) => {
  const classes = useStyles();
  const { totalCount, pagination, setPagination, sort, setSort } = useStudies();
  const { selectStudyhandler, selectedRows, studies } = props;
  const [openRiskDialog, setOpenRiskDialog] = useState(false);
  const [riskDialogRow, setRiskDialogRow] = useState(-1);
  const { t } = useTranslation();
  const [highlightRow, setHighlightRow] = useState(props.highlightRow);
  const [
    openOutreachFailureSummaryDialog,
    setOpenOutreachFailureSummaryDialog,
  ] = useState(false);
  const [
    outreachFailureSummaryDialogIndex,
    setOutreachFailureSummaryDialogIndex,
  ] = useState(-1);

  let history = useHistory();

  // property is the selected columnName to sort
  const handleRequestSort = (event, property) => {
    const isAsc =
      sort.orderBy === property && sort.order === "asc" ? "desc" : "asc";
    setSort({
      orderBy: property,
      order: isAsc,
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      selectStudyhandler(studies);
      return;
    }
    selectStudyhandler([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selectedRows.findIndex(
      (element) => element.id === row.id
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    selectStudyhandler(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    var pageValue = pagination.page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - pagination.page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      pageValue = 0;
    }
    setPagination({
      page: pageValue,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleEditStudy = (rowData, index) => {
    if (rowData) {
      history.push({
        pathname: "/studies/" + rowData.id + "/edit",
        state: { createMode: false, study: rowData, index: index },
      });
    }
  };

  const handleIngestHBE = (rowData) => {
    if (rowData) {
      history.push({
        pathname: "/prospects/" + rowData.id + "/import",
        state: { study: rowData },
      });
    }
  };

  const handleConductOutreach = (rowData) => {
    if (rowData) {
      history.push({
        pathname: "/outreach/" + rowData.id + "/conduct",
        state: { study: rowData },
      });
    }
  };

  const isSelected = (row) =>
    selectedRows.findIndex((element) => element.id === row.id) !== -1;

  const displayRiskDialog = (index) => {
    setOpenRiskDialog(true);
    setRiskDialogRow(index);
  };

  const closeRiskDialog = () => {
    setOpenRiskDialog(false);
    setRiskDialogRow(-1);
  };

  const closeOutreachFailureSummary = () => {
    setOpenOutreachFailureSummaryDialog(false);
  };

  useEffect(() => {
    if (props.highlightRow >= 0) {
      // after 1 second, dismiss the highligh color
      setTimeout(() => {
        setHighlightRow(-1);
      }, 1000);
    }
  }, [props.highlightRow]);

  const dialogTitle = (riskConditions, irbNumber) =>
    riskConditions.length === 1
      ? t("StudySingleRiskConditionTitle", {
          irbnumber: irbNumber,
        })
      : t("StudyRiskConditionTitle", {
          irbnumber: irbNumber,
          count: riskConditions.length,
        });

  return (
    <MuiPaper className={classes.tableScroll}>
      <MuiTableContainer>
        <MuiTable stickyHeader>
          <EnhancedTableHead
            numSelected={selectedRows.length}
            order={sort.order}
            orderBy={sort.orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={totalCount}
            styles={classes}
          />
          <MuiTableBody>
            {studies.map((row, index) => {
              const isItemSelected = isSelected(row);

              return (
                <MuiTableRow
                  hover
                  // onClick={event => handleClick(event, row.id)}
                  role="checkbox"
                  key={row.id}
                  selected={isItemSelected}
                  className={
                    (index === highlightRow ? classes.highlightRow : null,
                    classes.tableCell)
                  }
                >
                  <MuiTableCell padding="checkbox">
                    <MuiCheckbox
                      color="primary"
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, row)}
                    />
                  </MuiTableCell>
                  <MuiTableCell>{row.irbNumber}</MuiTableCell>
                  <MuiTableCell className={classes.protocolNumberTableCell}>
                    {row.protocolNumber}
                  </MuiTableCell>
                  <MuiTableCell>
                    {row.isHavingErrorSummary && (
                      <MuiTooltip
                        title={t("studyTable.row_tooltip_outreach_status_icon")}
                        arrow
                      >
                        <MuiIconButton
                          size="small"
                          onClick={(evt) => {
                            setOpenOutreachFailureSummaryDialog(true);
                            setOutreachFailureSummaryDialogIndex(index);
                          }}
                          color="secondary"
                        >
                          <MuiErrorOutlineIcon />
                        </MuiIconButton>
                      </MuiTooltip>
                    )}
                    {openOutreachFailureSummaryDialog &&
                      index === outreachFailureSummaryDialogIndex && (
                        <OutreachFailureSummary
                          onClose={closeOutreachFailureSummary}
                          open={openOutreachFailureSummaryDialog}
                          isDisplayConductOutreachButton={true}
                          studyId={row.id}
                        />
                      )}
                  </MuiTableCell>
                  <MuiTableCell>
                    {t("irbStatus." + row.protocolStatus)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {row.protocolSummaryAccrual
                      ? SummaryAccrual.SummaryAccrualTrue
                      : SummaryAccrual.SummaryAccrualFalse}
                  </MuiTableCell>
                  <MuiTableCell className={classes.nicknameTableCell}>
                    {row.nickname}
                  </MuiTableCell>
                  <MuiTableCell>
                    {row.honestBrokerRecruitmentStatus ===
                    RecruitmentStatus.UNINITIATED
                      ? ""
                      : t(
                          `recruitmentStatus.${row.honestBrokerRecruitmentStatus}`
                        )}
                  </MuiTableCell>
                  <MuiTableCell>
                    {
                      <>
                        {row.leadCrc && (
                          <div>
                            <PEMail emailAddress={row.leadCrc.email}>
                              {row.leadCrcName}
                            </PEMail>
                          </div>
                        )}
                        {row.pi && (
                          <div>
                            <PEMail emailAddress={row.pi.email}>
                              {row.piName}
                            </PEMail>
                          </div>
                        )}
                      </>
                    }
                  </MuiTableCell>
                  <MuiTableCell>
                    {
                      <>
                        <div>{row.protocolDeptName}</div>
                        <div>{row.division}</div>
                      </>
                    }
                  </MuiTableCell>
                  <MuiTableCell>
                    {row.honestBrokerRecruitmentStatus ===
                    RecruitmentStatus.UNINITIATED ? (
                      ""
                    ) : row.riskCount > 0 ? (
                      <div className={classes.riskContainer}>
                        <span className="riskcount">{row.riskCount}</span>
                        <span className="warningIcon">
                          <MuiWarningIcon
                            onClick={() => displayRiskDialog(index)}
                          />
                          {openRiskDialog && index === riskDialogRow && (
                            <PEErrorDialog
                              onClose={closeRiskDialog}
                              open={openRiskDialog}
                              errorsList={row.riskConditions}
                              title={dialogTitle(
                                row.riskConditions,
                                row.irbNumber
                              )}
                              primarySubHeading={t(`RiskConditionIssue`)}
                              secondarySubHeading={t(`RiskConditionSolution`)}
                              primaryContent={
                                errorSummaryContent.primaryContent
                              }
                              secondaryContent={
                                errorSummaryContent.secondaryContent
                              }
                            />
                          )}
                        </span>
                      </div>
                    ) : (
                      <MuiTooltip
                        title={t("studyTable.row_tooltip_recruitment_on_track")}
                        arrow
                      >
                        <div>{row.riskCount}</div>
                      </MuiTooltip>
                    )}
                  </MuiTableCell>
                  <MuiTableCell>
                    {row.recruited < row.timeElapsed ? (
                      <MuiTooltip
                        title={t("studyTable.row_tooltip_recruitment_behind")}
                        arrow
                      >
                        {
                          <>
                            <div>{row.recruited}</div>
                            <div>
                              {"(" +
                                row.prospectOnStudy +
                                "/" +
                                row.protocolTargetAccrual +
                                ")"}
                            </div>
                          </>
                        }
                      </MuiTooltip>
                    ) : (
                      <MuiTooltip
                        title={t("studyTable.row_tooltip_recruitment_on_track")}
                        arrow
                      >
                        {
                          <>
                            <div>{row.recruited}</div>
                            <div>
                              {"(" +
                                row.prospectOnStudy +
                                "/" +
                                row.protocolTargetAccrual +
                                ")"}
                            </div>
                          </>
                        }
                      </MuiTooltip>
                    )}
                  </MuiTableCell>
                  <MuiTableCell>
                    {row.honestBrokerRecruitmentStatus ===
                    RecruitmentStatus.UNINITIATED ? (
                      ""
                    ) : (
                      <>
                        <div>{row.timeElapsed}</div>
                        <div>
                          {"(" +
                            row.recruitmentDays +
                            "/" +
                            row.recruitmentTotalDays +
                            ")"}
                        </div>
                      </>
                    )}
                  </MuiTableCell>
                  <MuiTableCell>
                    {row.recruitmentEndDate
                      ? row.recruitmentEndDate.toLocaleDateString()
                      : null}
                  </MuiTableCell>
                  <MuiTableCell>
                    {row.followUpDate
                      ? row.followUpDate.toLocaleDateString()
                      : null}
                  </MuiTableCell>
                  <MuiTableCell className={classes.noteTableCell}>
                    {row.note && (
                      <MuiTooltip title={row.note} arrow>
                        <MuiTypography className={classes.noteTableCellHeight}>
                          {row.note}
                        </MuiTypography>
                      </MuiTooltip>
                    )}
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiGrid container justify="space-between">
                      <MuiGrid item xs={6}>
                        <MuiTooltip
                          title={t("studyTable.row_tooltip_edit_icon")}
                          arrow
                        >
                          <MuiIconButton
                            size="small"
                            onClick={(evt) => handleEditStudy(row, index)}
                          >
                            <MuiEditIcon />
                          </MuiIconButton>
                        </MuiTooltip>
                      </MuiGrid>
                      {row.honestBrokerStatus ===
                        HONEST_BROKER_ASSIGNMENT_STATUS.ASSIGNED && (
                        <>
                          <MuiGrid item xs={6}>
                            <MuiTooltip
                              title={t(
                                "studyTable.row_tooltip_ingest_hbe_icon"
                              )}
                              arrow
                            >
                              <MuiIconButton
                                size="small"
                                onClick={(evt) => handleIngestHBE(row)}
                              >
                                <MuiCloudUploadIcon />
                              </MuiIconButton>
                            </MuiTooltip>
                          </MuiGrid>
                          <MuiGrid item xs={6}>
                            <MuiTooltip
                              title={t(
                                "studyTable.row_tooltip_conduct_outreach_icon"
                              )}
                              arrow
                            >
                              <MuiIconButton
                                size="small"
                                onClick={(evt) => handleConductOutreach(row)}
                              >
                                <MuiRecordVoiceOverIcon />
                              </MuiIconButton>
                            </MuiTooltip>
                          </MuiGrid>
                          <MuiGrid item xs={6}>
                            <MuiTooltip
                              title={t(
                                "studyTable.studySummaryDashboardIconTooltip"
                              )}
                              arrow
                            >
                              <MuiLink
                                href={`${handleTableauUrls()}/StudySummaryDashboard?irb_number=${
                                  row.irbNumber
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <MuiIconButton size="small">
                                  <MuiTableChartIcon />
                                </MuiIconButton>
                              </MuiLink>
                            </MuiTooltip>
                          </MuiGrid>
                          <MuiGrid item xs={12}>
                            <MuiTooltip
                              title={t(
                                "studyTable.participantBreakdownDashboardIconTooltip"
                              )}
                              arrow
                            >
                              <MuiLink
                                href={`${handleTableauUrls()}/ParticipantBreakdownDashboard?irb_number=${
                                  row.irbNumber
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <MuiIconButton size="small">
                                  <MuiAssessmentIcon />
                                </MuiIconButton>
                              </MuiLink>
                            </MuiTooltip>
                          </MuiGrid>
                        </>
                      )}
                    </MuiGrid>
                  </MuiTableCell>
                </MuiTableRow>
              );
            })}
          </MuiTableBody>
        </MuiTable>
      </MuiTableContainer>
      <MuiTablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </MuiPaper>
  );
};

export default StudyTable;
