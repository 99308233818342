import { useTranslation } from "react-i18next";
import {
  CardContent as MuiCardContent,
  FormControlLabel as MuiFormControlLabel,
  Checkbox as MuiCheckbox,
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  Grid as MuiGrid,
  Card as MuiCard,
  TextField as MuiTextField,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { useStyles } from "app/study/CreateEditStudyFormStyles";
import { StudyContactRole } from "app/shared/constants";

const StudyContactsTab = () => {
  const { values, setFieldValue, errors, handleBlur, touched } =
    useFormikContext();

  const { t } = useTranslation();
  const classes = useStyles();
  const { studyContacts, recruitmentEmail, recruitmentPhone } = values;

  const displayACard = (contact, contactIndex) => {
    return (
      <MuiGrid
        container
        direction="column"
        key={contact.staffId}
        className={classes.contentNoWrap}
      >
        <MuiCard
          key={contact.staffId}
          className={classes.customContactGridWidth}
        >
          <MuiCardContent>
            <MuiGrid item>
              <MuiTextField
                fullWidth
                name="name"
                variant="outlined"
                margin="dense"
                label={t(`formLabel.name`)}
                value={contact.firstName + " " + contact.lastName}
                disabled
              />
            </MuiGrid>
            <MuiGrid item>
              <MuiTextField
                fullWidth
                name="role"
                variant="outlined"
                margin="dense"
                label={t(`formLabel.role`)}
                value={contact.role}
                disabled
              />
            </MuiGrid>
            <MuiGrid item>
              <MuiTextField
                fullWidth
                name="sunetid"
                variant="outlined"
                margin="dense"
                label={t(`formLabel.sunetid`)}
                value={contact.sunetid}
                disabled
              />
            </MuiGrid>
            <MuiGrid item>
              <MuiTextField
                fullWidth
                name="email"
                variant="outlined"
                margin="dense"
                label={t(`formLabel.email`)}
                value={contact.email}
                disabled
              />
            </MuiGrid>
            <MuiGrid item>
              <MuiTextField
                fullWidth
                name={`${contact.staffId}_${contact.role}_note`}
                label={t(`formLabel.note`)}
                variant="outlined"
                margin="dense"
                onChange={(event) => {
                  setFieldValue(
                    `studyContacts[${contactIndex}].note`,
                    event.target.value
                  );
                }}
                value={contact.note}
              />
            </MuiGrid>
            {contact.role !== StudyContactRole.PI && (
              <MuiGrid item>
                <MuiFormControl error={errors.studyContacts ? true : false}>
                  <MuiFormControlLabel
                    control={
                      <MuiCheckbox
                        checked={contact.leadCrc}
                        color="primary"
                        onChange={(evt, newValue) => {
                          setFieldValue(
                            `studyContacts[${contactIndex}].leadCrc`,
                            newValue
                          );
                        }}
                      />
                    }
                    label={t("formLabel.leadCRC")}
                    name={`${contact.staffId}_${contact.role}_leadCRC`}
                  />
                  {errors.studyContacts && (
                    <MuiFormHelperText focused={true}>
                      {t("formValidation.oneMainCRC")}
                    </MuiFormHelperText>
                  )}
                </MuiFormControl>
              </MuiGrid>
            )}
          </MuiCardContent>
        </MuiCard>
      </MuiGrid>
    );
  };

  return (
    <MuiGrid container spacing={2} className={classes.contentNoWrap}>
      <MuiGrid container direction="column" item xs>
        <MuiGrid item className={classes.customContactGridWidth}>
          <MuiTextField
            fullWidth
            name="recruitmentEmail"
            label={t(`formLabel.recruitmentEmail`)}
            value={recruitmentEmail}
            variant="outlined"
            margin="dense"
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue("recruitmentEmail", e.target.value);
            }}
            error={
              touched.recruitmentEmail && errors.recruitmentEmail ? true : false
            }
            helperText={
              touched.recruitmentEmail &&
              errors.recruitmentEmail &&
              t("formValidation.invalidEmail")
            }
          />
        </MuiGrid>
        <MuiGrid item className={classes.customContactGridWidth}>
          <MuiTextField
            fullWidth
            name="recruitmentPhone"
            label={t(`formLabel.recruitmentPhone`)}
            value={recruitmentPhone}
            variant="outlined"
            margin="dense"
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue("recruitmentPhone", e.target.value);
            }}
            error={
              touched.recruitmentPhone && errors.recruitmentPhone ? true : false
            }
            helperText={
              touched.recruitmentPhone &&
              errors.recruitmentPhone &&
              t("formValidation.invalidPhoneNumber")
            }
          />
        </MuiGrid>
      </MuiGrid>

      {/* PI */}
      {studyContacts &&
        studyContacts.map((contact, index) => {
          return (
            contact.role === StudyContactRole.PI && (
              <MuiGrid item xs>
                {displayACard(contact, index)}
              </MuiGrid>
            )
          );
        })}

      {/* PRIMARY_CRC */}
      {studyContacts &&
        studyContacts.map((contact, index) => {
          return (
            contact.role !== StudyContactRole.PI && (
              <MuiGrid item xs>
                {displayACard(contact, index)}
              </MuiGrid>
            )
          );
        })}
    </MuiGrid>
  );
};

export default StudyContactsTab;
