import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button as MuiButton,
  Grid as MuiGrid,
  Tooltip as MuiTooltip,
} from "@material-ui/core";
import {
  FormatBold as MuiFormatBoldIcon,
  FormatUnderlined as MuiFormatUnderlinedIcon,
  FormatItalic as MuiFormatItalicIcon,
  StrikethroughS as MuiStrikethroughSIcon,
  FormatListBulleted as MuiFormatListBulletedIcon,
  FormatListNumbered as MuiFormatListNumberedIcon,
  InsertLink as MuiInsertLinkIcon,
} from "@material-ui/icons";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import { buttonStyle, useStyles } from "./TemplatesConfigStyles";
import { useAlerts } from "common";

// extensions array
const extensions = [
  StarterKit,
  Underline,
  Link.configure({
    openOnClick: true,
    autolink: true,
    defaultProtocol: "https",
    protocols: ["http", "https"],
  }),
];

const RichTextEditor = ({
  setOpenDialog,
  tokenIndex,
  perTemp,
  selectedTempIndex,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setAlert } = useAlerts();

  const [isRichTextEditorDirty, setIsRichTextEditorDirty] = useState(false);
  const [openASConfirmation, setOpenASConfirmation] = useState(false);

  const content = perTemp.value;

  const editor = useEditor({
    extensions,
    autofocus: false,
    content,
    editorProps: {
      attributes: {
        class: classes.ProseMirrorFocused,
      },
    },
    onUpdate: () => {
      setIsRichTextEditorDirty(true);
    },
  });

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    // update link
    try {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    } catch (err) {
      setAlert("error", err.message);
    }
  }, [editor, setAlert]);

  if (!editor) {
    return null;
  }
  const editorValue = editor.getHTML();

  const strippedContent = () => {
    return (
      editorValue
        .replace(/<p>\s*<\/p>/g, "")
        .replace(/<br\s*\/?>/g, "")
        .replace(/<\/?[^>]+(>|$)/g, "")
        .replace(/[\n\r]/g, "")
        .trim() === ""
    );
  };

  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);

    if (cancelChoice) {
      setFieldValue(
        `studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`,
        content
      );
      setIsRichTextEditorDirty(false);
      setOpenDialog(false);
    }
  };

  return (
    <MuiGrid
      container
      style={{
        border: "1px solid black",
        padding: "10px",
      }}
    >
      <MuiGrid item>
        <MuiGrid
          item
          style={{
            padding: "10px",
          }}
        >
          <ConfirmationDialog
            open={openASConfirmation}
            onClose={closeCancelASConfirmation}
            message={t("OutreachDetails.confirmationMessageCancel")}
            okLabel={t("ConductOutreachPage.yesLabel")}
            cancelLabel={t("ConductOutreachPage.noLabel")}
          />

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.bold")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={editor.isActive("bold") ? "contained" : "outlined"}
              onClick={(e) => {
                editor.chain().focus().toggleBold().run();
              }}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              className={editor.isActive("bold") ? "is-active" : ""}
            >
              <MuiFormatBoldIcon />
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.underline")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={editor.isActive("underline") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              className={editor.isActive("underline") ? "is-active" : ""}
            >
              <MuiFormatUnderlinedIcon />
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.italic")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={editor.isActive("italic") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
              className={editor.isActive("italic") ? "is-active" : ""}
            >
              <MuiFormatItalicIcon />
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.strike")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={editor.isActive("strike") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={!editor.can().chain().focus().toggleStrike().run()}
              className={editor.isActive("strike") ? "is-active" : ""}
            >
              <MuiStrikethroughSIcon />
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.paragraph")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={editor.isActive("paragraph") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().setParagraph().run()}
              className={editor.isActive("paragraph") ? "is-active" : ""}
            >
              Para
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.insertLink")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              onClick={setLink}
              variant={editor.isActive("link") ? "contained" : "outlined"}
              disabled={
                !editor.can().chain().focus().extendMarkRange("link").run()
              }
              className={editor.isActive("link") ? "is-active" : ""}
            >
              <MuiInsertLinkIcon />
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.headingH1")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={
                editor.isActive("heading", { level: 1 })
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              className={
                editor.isActive("heading", { level: 1 }) ? "is-active" : ""
              }
            >
              H1
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.headingH2")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={
                editor.isActive("heading", { level: 2 })
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              className={
                editor.isActive("heading", { level: 2 }) ? "is-active" : ""
              }
            >
              H2
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.headingH3")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={
                editor.isActive("heading", { level: 3 })
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              className={
                editor.isActive("heading", { level: 3 }) ? "is-active" : ""
              }
            >
              H3
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.bulletedList")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={editor.isActive("bulletList") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={editor.isActive("bulletList") ? "is-active" : ""}
            >
              <MuiFormatListBulletedIcon />
            </MuiButton>
          </MuiTooltip>

          <MuiTooltip
            title={t("templateConfigTable.richTextEditorToolTip.numberedList")}
            arrow
            placement="top"
          >
            <MuiButton
              style={buttonStyle}
              variant={
                editor.isActive("orderedList") ? "contained" : "outlined"
              }
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={editor.isActive("orderedList") ? "is-active" : ""}
            >
              <MuiFormatListNumberedIcon />
            </MuiButton>
          </MuiTooltip>
        </MuiGrid>
        <EditorContent
          editor={editor}
          style={{
            width: "800px",
          }}
          className={classes.editorContent}
        />
      </MuiGrid>

      <MuiGrid container spacing={3} justifyContent="center">
        <MuiGrid item>
          <MuiButton
            variant="outlined"
            onClick={() => {
              if (!isRichTextEditorDirty) {
                setOpenDialog(false);
              } else {
                setOpenASConfirmation(true);
              }
            }}
          >
            {t(`formLabel.buttonCancel`)}
          </MuiButton>
        </MuiGrid>
        <MuiGrid item>
          <MuiButton
            variant="contained"
            color="primary"
            disabled={!isRichTextEditorDirty || strippedContent()}
            onClick={() => {
              setFieldValue(
                `studyTemplates.[${selectedTempIndex}].getStudyTemp`,
                true
              );

              setFieldValue(
                `studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`,
                editorValue
              );

              setOpenDialog(false);
            }}
          >
            {t("formLabel.buttonOk")}
          </MuiButton>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};

export default RichTextEditor;
