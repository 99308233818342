import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableBody as MuiTableBody,
  InputAdornment as MuiInputAdornment,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { TargetAccrualsRoot } from "./StudyTargetAccrualsStyles";
import { roundTo } from "../shared/utils";
import { useStudies } from "../services/studiesService";

const applyFormat = (val, format) => {
  if (val === undefined || val === null) return "";

  if (typeof val === "object" && "displayName" in val) return val.displayName;

  if (typeof format === "function") return format(val);

  return val.toString();
};

const headCells = [
  {
    id: "group",
    label: ["head_label_group"],
    width: "16em",
    //    tooltip: "head_tooltip_group",
    totalRender: "",
  },
  {
    id: "value",
    label: ["head_label_value"],
    //    tooltip: "head_tooltip_value",
    align: "right",
    totalRender: "TOTAL",
    width: "16em",
  },
  {
    id: "benchmark",
    label: ["head_label_benchmark"],
    format: (x) => (x * 100).toFixed(2) + "%",
    width: "14em",
    align: "right",
    totalRender: (val) => applyFormat(val, (x) => (x * 100).toFixed(2) + "%"),
  },
  {
    id: "target",
    label: ["head_label_target"],
    format: (x) => (x * 100).toFixed(2) + "%",
    width: "14em",
    align: "right",
    totalRender: (val) => applyFormat(val, (x) => (x * 100).toFixed(2)),
  },
  {
    id: "actual",
    label: ["head_label_actual"],
    format: (x) => (!isNaN(x) ? (x * 100).toFixed(2) + "%" : "-"),
    width: "16em",
    align: "right",
    totalRender: (val, targetAccruals) =>
      targetAccruals
        ? applyFormat(val, (x) => (!isNaN(x) ? (x * 100).toFixed(2) + "%" : ""))
        : "",
  },
  {
    id: "delta",
    label: ["head_label_delta"],
    format: (x) => (!isNaN(x) ? (x * 100).toFixed(2) + "%" : "-"),
    width: "10em",
    align: "right",
    totalRender: "",
  },
  {
    id: "ranking",
    label: ["head_label_ranking"],
    width: "2em",
    align: "center",
    totalRender: "",
  },
];

const StudyTargetAccrualsTable = ({ isOptimizationEnabled }) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const {
    targetAccruals,
    protocolTargetAccrual,
    protocolTargetAccrualc,
    protocolSummaryAccrual,
  } = values;

  const {
    recalcTotalsInTargetAccruals,
    validateValueTarget,
    preValidateValueTarget,
  } = useStudies();

  const [rendFlag, setRendFlag] = useState(false);

  // USED ONLY FOR EnhancedTableHead
  const headCellLabels = {
    value: [`Target Accrual = ${protocolTargetAccrual || "0"}`],
  };

  const sanitizeTargetInput = (event, setFieldValue, fieldName) => {
    const val = event.target.value + "";
    const cleaned = preValidateValueTarget(val);

    if (val !== cleaned) {
      // TODO
      // setFieldTouched(fieldName, true);
      return false;
    } else {
      return true;
    }
  };

  const displayHeadCell = (headCell, t, headCellLabels) =>
    headCellLabels && headCellLabels[headCell.id] ? (
      headCellLabels[headCell.id].length > 1 ? (
        <TargetAccrualsRoot.StudyTableHead.TwoRowHeadCell>
          <span>{headCellLabels[headCell.id][0]}</span>
          <span>{headCellLabels[headCell.id][1]}</span>
        </TargetAccrualsRoot.StudyTableHead.TwoRowHeadCell>
      ) : (
        headCellLabels[headCell.id][0]
      )
    ) : headCell.label.length > 1 ? (
      <TargetAccrualsRoot.StudyTableHead.TwoRowHeadCell>
        <span>{t("targetAccrualsTable." + headCell.label[0])}</span>
        <span>{t("targetAccrualsTable." + headCell.label[1])}</span>
      </TargetAccrualsRoot.StudyTableHead.TwoRowHeadCell>
    ) : (
      t("targetAccrualsTable." + headCell.label[0])
    );

  const displayHeadCellWithToolTip = (headCell, t, headCellLabels) => (
    <TargetAccrualsRoot.LightTooltip
      title={t("targetAccrualsTable." + headCell.tooltip)}
      placement="top"
    >
      <div>{displayHeadCell(headCell, t, headCellLabels)}</div>
    </TargetAccrualsRoot.LightTooltip>
  );

  const EnhancedTableHead = ({ headCellLabels }) => {
    return (
      <TargetAccrualsRoot.StudyTableHead className={"gray-med-bg"}>
        <TargetAccrualsRoot.StudyTableRow>
          {headCells.map((headCell) => (
            <TargetAccrualsRoot.StudyTableHead.StudyTableHeadCell
              key={headCell.id}
              headcell={headCell}
              align={headCell.align || "inherit"}
            >
              {headCell.tooltip
                ? displayHeadCellWithToolTip(headCell, t, headCellLabels)
                : displayHeadCell(headCell, t, headCellLabels)}
            </TargetAccrualsRoot.StudyTableHead.StudyTableHeadCell>
          ))}
        </TargetAccrualsRoot.StudyTableRow>
      </TargetAccrualsRoot.StudyTableHead>
    );
  };

  //use "-" instaed of "0" when target accrual is zero
  const getProtocolAccrualDependentValues = (value, format) => {
    return protocolTargetAccrual ? applyFormat(value, format) : "-";
  };

  return (
    <TargetAccrualsRoot>
      <MuiTableContainer>
        <MuiTable>
          <EnhancedTableHead headCellLabels={headCellLabels} />
          <MuiTableBody>
            {targetAccruals &&
            targetAccruals.categories &&
            targetAccruals.categories.length
              ? targetAccruals.categories.map((categoryObj, index) => {
                  const realTimeCalcForTargetAcc = (categoryObject) => {
                    categoryObject.totals.target = categoryObject.values.reduce(
                      (sum, item) => sum + item.target,
                      0
                    );

                    const targetFieldValueArray =
                      values.targetAccruals?.categories[index]?.values?.map(
                        (val) => {
                          const sum = roundTo(+val["target"], 4).toFixed(5);
                          return sum * 1;
                        }
                      );

                    recalcTotalsInTargetAccruals(
                      targetAccruals,
                      index,
                      targetFieldValueArray,
                      protocolTargetAccrualc
                    );

                    return categoryObject.totals.target !== 1 ? true : false;
                  };
                  return (
                    <>
                      <TargetAccrualsRoot.StudyTableRow althighlight={1}>
                        <TargetAccrualsRoot.TableRowCell
                          colSpan={headCells.length}
                        >
                          {headCells.map((_, i) =>
                            i === 0 ? categoryObj.label.displayName : ""
                          )}
                        </TargetAccrualsRoot.TableRowCell>
                      </TargetAccrualsRoot.StudyTableRow>

                      {/*  MAIN IMPLEMENTATINS */}
                      {categoryObj.values.map((valueItem, vInd) => {
                        const fieldNames = categoryObj.values.map(
                          (v, valueIndex) =>
                            `targetAccruals.categories.${index}.values.${valueIndex}.target`
                        );

                        return (
                          <TargetAccrualsRoot.StudyTableRow
                            key={`${categoryObj.label.id}_${index}`}
                          >
                            {headCells.map((headCellObj, headIndex) => (
                              <TargetAccrualsRoot.TableRowCell
                                key={headCellObj.id}
                                align={headCellObj.align || "inherit"}
                                className={
                                  headCellObj.id === "delta" &&
                                  +valueItem["delta"] < 0
                                    ? "negative"
                                    : ""
                                }
                              >
                                {headCellObj.id === "target" ? (
                                  <TargetAccrualsRoot.MuiTextField
                                    onChange={(e) => {
                                      if (
                                        !sanitizeTargetInput(
                                          e,
                                          setFieldValue,
                                          fieldNames[vInd]
                                        ) ||
                                        !validateValueTarget(e.target.value)
                                      ) {
                                        setRendFlag(!rendFlag);
                                        return;
                                      }
                                      setFieldTouched(fieldNames[vInd], true);
                                      setFieldValue(
                                        fieldNames[vInd],
                                        Number(e.target.value) / 100
                                      );

                                      setRendFlag(!rendFlag);
                                    }}
                                    error={realTimeCalcForTargetAcc(
                                      categoryObj
                                    )}
                                    required
                                    value={(
                                      targetAccruals.categories[index].values[
                                        vInd
                                      ]["target"] * 100.0
                                    ).toFixed(2)}
                                    InputProps={{
                                      endAdornment: (
                                        <MuiInputAdornment position="end">
                                          %
                                        </MuiInputAdornment>
                                      ),
                                    }}
                                    name={fieldNames[vInd]}
                                    disabled={
                                      protocolSummaryAccrual ||
                                      !isOptimizationEnabled
                                    }
                                    variant="outlined"
                                  />
                                ) : (
                                  <span>
                                    {[("delta", "actual", "ranking")].includes(
                                      headCellObj.id
                                    )
                                      ? getProtocolAccrualDependentValues(
                                          valueItem[headCellObj.id],
                                          headCellObj.format
                                        )
                                      : applyFormat(
                                          valueItem[headCellObj.id],
                                          headCellObj.format
                                        )}
                                  </span>
                                )}
                              </TargetAccrualsRoot.TableRowCell>
                            ))}
                          </TargetAccrualsRoot.StudyTableRow>
                        );
                      })}

                      {/*  TOTAL ROW */}
                      <TargetAccrualsRoot.StudyTableRow
                        className={"totals-row"}
                      >
                        {headCells.map((headCellItem) => (
                          <TargetAccrualsRoot.TableRowCell
                            key={headCellItem.id}
                            align={headCellItem.align || "inherit"}
                          >
                            {headCellItem.id === "target" ? (
                              <TargetAccrualsRoot.TotalField
                                value={
                                  typeof headCellItem.totalRender === "string"
                                    ? headCellItem.totalRender
                                    : headCellItem.totalRender(
                                        categoryObj.totals[headCellItem.id]
                                      )
                                }
                                error={categoryObj.totals["target"] !== 1}
                                helperText={
                                  categoryObj.totals["target"] !== 1
                                    ? t(`formValidation.wrongTotalPct`)
                                    : ""
                                }
                                name={`categories.${index}.totals.target`}
                                InputProps={{
                                  endAdornment: (
                                    <MuiInputAdornment position="end">
                                      %
                                    </MuiInputAdornment>
                                  ),
                                }}
                                variant="outlined"
                                disabled
                              />
                            ) : typeof headCellItem.totalRender === "string" ? (
                              headCellItem.totalRender
                            ) : (
                              headCellItem.totalRender(
                                categoryObj.totals[headCellItem.id],
                                protocolTargetAccrual
                              )
                            )}
                          </TargetAccrualsRoot.TableRowCell>
                        ))}
                      </TargetAccrualsRoot.StudyTableRow>
                    </>
                  );
                })
              : null}
          </MuiTableBody>
        </MuiTable>
      </MuiTableContainer>
    </TargetAccrualsRoot>
  );
};

export const StudyTargetAccrualsTab = (props) => {
  return <StudyTargetAccrualsTable {...props} />;
};

export default StudyTargetAccrualsTab;
