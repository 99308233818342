import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Divider as MuiDivider,
  Button as MuiButton,
  TextField as MuiTextField,
  FormControl as MuiFormControl,
  Select as MuiSelect,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
} from "@material-ui/core";
import { Loop as MuiLoopIcon } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from "app/prospect/ProspectFiltersStyles";
import { useProspectContext } from "app/services/prospectContext";
import { ProspectDefaults } from "app/shared/constants";
import { encodeBase64 } from "app/shared/utils";

const ProspectFilters = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();
  const {
    filters,
    setFilters,
    setInitialLoad,
    setSort,
    setPagination,
    pagination,
  } = useProspectContext();

  // Controllable State - First Name
  const [firstName, setFirstName] = useState(filters.firstName);

  // Controllable State - Last Name
  const [lastName, setLastName] = useState(filters.lastName);

  // Controllable State - Phone
  const [phone, setPhone] = useState(filters.phone);

  // Controllable State - Email
  const [email, setEmail] = useState(filters.email);

  // Controllable State - MRN
  const [mrn, setMRN] = useState(filters.mrn);

  const [emailError, setEmailError] = useState(false);

  const [birthDate, setBirthDate] = useState(filters.birthDate);

  const [isOptedOut, setIsOptedOut] = useState(filters.isOptedOut);
  const [isHavingConcern, setIsHavingConcern] = useState(
    filters.isHavingConcern
  );

  const handleResetProspectFilters = () => {
    // reset filters to default one
    setFilters({
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      mrn: null,
      birthDate: null,
      isOptedOut: null,
      isHavingConcern: null,
    });
    setFirstName("");
    setLastName("");
    setMRN("");
    setPhone("");
    setEmail("");
    setBirthDate(null);
    setIsOptedOut(null);
    setIsHavingConcern(null);
    setInitialLoad(true);
    setSort({
      orderBy: ProspectDefaults.PROSPECTDEFAULTORDERBY,
      order: ProspectDefaults.PROSPECTDEFAULTORDER,
    });
    setPagination({
      page: ProspectDefaults.PROSPECTDEFAULTPAGE,
      rowsPerPage: ProspectDefaults.PROSPECTDEFAULTPAGESIZE,
    });
    setEmailError(false);
  };

  const handleApplyLookupFilters = () => {
    setFilters({
      firstName: (firstName && firstName.trim()) || null,
      lastName: (lastName && lastName.trim()) || null,
      phone: (phone && phone.trim()) || null,
      email: (email && email.trim()) || null,
      mrn: (mrn && mrn.trim()) || null,
      birthDate: birthDate || null,
      isOptedOut: isOptedOut,
      isHavingConcern: isHavingConcern,
    });
    setInitialLoad(false);
    setPagination({
      ...pagination,
      page: 0,
    });
  };

  useEffect(() => {
    const params = {
      firstName,
      lastName,
      phone,
      email,
      mrn,
      birthDate,
      isOptedOut,
      isHavingConcern,
    };
    const queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => {
        return `${key}=${encodeBase64(params[key])}`;
      })
      .join("&");
    history.push(`${history.location.pathname}?${queryParams}`);
  }, [
    firstName,
    lastName,
    phone,
    email,
    mrn,
    history,
    birthDate,
    isOptedOut,
    isHavingConcern,
  ]);

  return (
    <MuiGrid container>
      <MuiGrid item xs={12} className={classes.headerMargin}>
        <MuiTypography variant="h6" noWrap>
          {t("ProspectFilters.title")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid item xs={12}>
        <MuiDivider />
      </MuiGrid>
      <MuiGrid
        item
        xs={12}
        className={classes.headerMargin}
        container
        justify={"flex-end"}
      >
        <MuiGrid item xs={11} container justify={"space-between"}>
          <MuiGrid item xs={8}>
            <MuiButton
              startIcon={<MuiLoopIcon />}
              onClick={handleResetProspectFilters}
            >
              {t("formLabel.buttonResetSearchCriteria")}
            </MuiButton>
          </MuiGrid>
          <MuiGrid item xs={3}>
            <MuiButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleApplyLookupFilters}
              disabled={emailError}
            >
              {t("formLabel.buttonSearch")}
            </MuiButton>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid item xs={12} className={classes.contentMargin}>
        {/* MRN Number - MuiTextField Input */}
        <MuiTextField
          id="mrnNumber"
          label={t("LookupProspect.tableColumnMRN")}
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t("LookupProspect.tableColumnMRN")}
          fullWidth
          autoComplete="off"
          value={mrn || ""}
          onChange={(event) => {
            setMRN(event.target.value);
          }}
        />
      </MuiGrid>
      <MuiGrid item xs={12} className={classes.contentMargin}>
        {/* First Name - MuiTextField Input */}
        <MuiTextField
          id="firstName"
          label={t("LookupProspect.tableColumnFirstName")}
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t("LookupProspect.tableColumnFirstName")}
          fullWidth
          autoComplete="off"
          value={firstName || ""}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
        />
      </MuiGrid>
      <MuiGrid item xs={12} className={classes.contentMargin}>
        {/* Last Name - MuiTextField Input */}
        <MuiTextField
          id="lastName"
          label={t("LookupProspect.tableColumnLastName")}
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t("LookupProspect.tableColumnLastName")}
          fullWidth
          autoComplete="off"
          value={lastName || ""}
          onChange={(event) => {
            setLastName(event.target.value);
          }}
        />
      </MuiGrid>
      <MuiGrid item xs={12} className={classes.contentMargin}>
        {/* Phone - MuiTextField Input */}
        <MuiTextField
          id="phone"
          label={t("LookupProspect.tableColumnPhone")}
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t("LookupProspect.tableColumnPhone")}
          fullWidth
          value={phone || ""}
          onChange={(event) => {
            setPhone(event.target.value);
          }}
          autoComplete="off"
        />
      </MuiGrid>
      <MuiGrid item xs={12} className={classes.contentMargin}>
        {/* Email - MuiTextField Input */}
        <MuiTextField
          id="email"
          label={t("LookupProspect.tableColumnEmail")}
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t("LookupProspect.tableColumnEmail")}
          fullWidth
          autoComplete="off"
          value={email || ""}
          onChange={(event) => {
            const pattern = new RegExp(
              "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
            );
            setEmailError(false);
            if (!pattern.test(event.target.value)) {
              setEmailError(true);
              !event.target.value && setEmailError(false);
            }
            setEmail(event.target.value);
          }}
          error={emailError}
          helperText={emailError && t("ProspectFilters.invalidEmailAddressMsg")}
        />
      </MuiGrid>
      <MuiGrid item xs={12} className={classes.contentMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiKeyboardDatePicker
            margin="dense"
            label={t("LookupProspect.tableColumnDOB")}
            placeholder={t("LookupProspect.tableColumnDOB")}
            format="MM/dd/yyyy"
            value={birthDate}
            onChange={(date) => setBirthDate(date)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            variant="inline"
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </MuiGrid>
      <MuiGrid item xs={12} className={classes.contentMargin}>
        <MuiFormControl
          variant="outlined"
          fullWidth
          margin="dense"
          className={classes.selectMarginDense}
        >
          <MuiInputLabel id="OptedOut" shrink>
            {t(`LookupProspect.tableColumnOptOut`)}
          </MuiInputLabel>
          <MuiSelect
            label={t(`LookupProspect.tableColumnOptOut`)}
            labelId="OptedOut"
            value={isOptedOut}
            onChange={(evt) => setIsOptedOut(evt.target.value)}
            name="OptedOut"
            displayEmpty
            notched
          >
            <MuiMenuItem value={""}>{t("formLabel.none")}</MuiMenuItem>
            <MuiMenuItem value={"true"}>{t("formLabel.switchYes")}</MuiMenuItem>
          </MuiSelect>
        </MuiFormControl>
      </MuiGrid>
      <MuiGrid item xs={12} className={classes.contentMargin}>
        <MuiFormControl
          variant="outlined"
          fullWidth
          margin="dense"
          className={classes.selectMarginDense}
        >
          <MuiInputLabel id="concernFile" shrink>
            {t(`LookupProspect.tableColumnConcernOnFile`)}
          </MuiInputLabel>
          <MuiSelect
            label={t(`LookupProspect.tableColumnConcernOnFile`)}
            labelId="concernFile"
            value={isHavingConcern}
            onChange={(evt) => setIsHavingConcern(evt.target.value)}
            name="OptedOut"
            displayEmpty
            notched
          >
            <MuiMenuItem value={""}>{t("formLabel.none")}</MuiMenuItem>
            <MuiMenuItem value={"true"}>{t("formLabel.switchYes")}</MuiMenuItem>
          </MuiSelect>
        </MuiFormControl>
      </MuiGrid>
    </MuiGrid>
  );
};

export default ProspectFilters;
