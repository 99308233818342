import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button as MuiButton,
  Dialog as MuiDialog,
} from "@material-ui/core";
import { Warning as MuiWarningIcon } from "@material-ui/icons";
import { useStyles } from "app/shared/UI/PEWarningDialogStyles";

// this is a warning dialog
const WarningDialog = (props) => {
  const { onClose, open, title, primaryMessage, secondaryMessage, okLabel } =
    props;
  const classes = useStyles();

  const handleOk = () => {
    onClose(false);
  };

  return (
    <MuiDialog open={open}>
      <MuiDialogTitle id="confirmation-dialog-title">
        <MuiWarningIcon className={classes.warning} />
        {title || ""}
      </MuiDialogTitle>
      <MuiDialogContent>
        <div>{primaryMessage || ""}</div>
        <div>{secondaryMessage || ""}</div>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton onClick={handleOk} color="primary">
          {okLabel ? okLabel : "OK"}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default WarningDialog;
