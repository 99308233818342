import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  Button as MuiButton,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  ListItemText as MuiListItemText,
  List as MuiList,
  ListItem as MuiListItem,
  Typography as MuiTypography,
  Divider as MuiDivider,
} from "@material-ui/core";
import { ErrorOutline as MuiErrorOutlineIcon } from "@material-ui/icons";
import { useAlerts } from "common";
import { format } from "date-fns";
import { useStyles } from "app/shared/UI/OutreachFailureSummaryStyles";
import {
  getOutreachFailureSummary,
  clearOutreachFailureSummary,
  useStudies,
} from "app/services/studiesService";
import { renderContentBySplitingOnFullStop } from "../utils";

const OutreachFailureSummary = (props) => {
  const {
    onClose,
    open,
    isDisplayConductOutreachButton,
    studyId,
    outreachRefresh,
    setOutreachRefresh,
    history,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [outreachFailureSummaryReport, setOutreachFailureSummaryReport] =
    useState([]);
  const [irbNumber, setIRBNumber] = useState("");

  useEffect(() => {
    getOutreachFailureSummary(
      studyId,
      setIRBNumber,
      setOutreachFailureSummaryReport,
      setAlert
    ); // eslint-disable-next-line
  }, [studyId]);

  const { studies, setStudies } = useStudies();

  const handleClearAlert = () => {
    setIRBNumber("");
    clearOutreachFailureSummary(
      studyId,
      setOutreachFailureSummaryReport,
      setAlert,
      studies,
      setStudies,
      setOutreachRefresh,
      outreachRefresh,
      history
    );
    onClose();
  };

  return (
    <MuiDialog open={open} onClose={onClose} maxWidth="xs">
      <MuiDialogTitle id="risk-condition-dialog-title" disableTypography>
        <MuiErrorOutlineIcon
          color="secondary"
          className={classes.errorOutlineIcon}
        />
        {t(`OutreachFailureSummary.title`)}
      </MuiDialogTitle>
      <MuiDivider className={classes.divider} />
      <MuiDialogContent className={classes.dialogContent}>
        <MuiList>
          <MuiListItem alignItems="center" dense={true}>
            <MuiListItemText
              disableTypography
              primary={
                <MuiTypography variant="subtitle1">
                  {irbNumber && t(`OutreachFailureSummary.irb`)} {irbNumber}
                </MuiTypography>
              }
            />
          </MuiListItem>
          {outreachFailureSummaryReport &&
          outreachFailureSummaryReport.length ? (
            outreachFailureSummaryReport.map((row, index) => (
              <>
                <MuiListItem alignItems="center" dense={true}>
                  <MuiListItemText
                    disableTypography
                    primary={
                      <MuiTypography variant="subtitle1">
                        {(row.scheduleType === "ONE_TIME_ONLY" &&
                          t(`OutreachFailureSummary.outreach`)) ||
                          t(`OutreachFailureSummary.recurringSchedule`)}{" "}
                        {row.waveName}
                      </MuiTypography>
                    }
                    secondary={
                      <MuiTypography variant="caption" color="textSecondary">
                        {format(new Date(row.issueDate), "yyyy-MM-dd hh:mm a")}{" "}
                        {row.failureReason
                          ? renderContentBySplitingOnFullStop(row.failureReason)
                          : ""}
                      </MuiTypography>
                    }
                  />
                </MuiListItem>
                <MuiDivider />
              </>
            ))
          ) : (
            <MuiTypography align="center" variant="subtitle1">
              {t(`OutreachFailureSummary.noSummaryAvailable`)}
            </MuiTypography>
          )}
        </MuiList>
      </MuiDialogContent>
      <MuiDialogActions
        className={
          (isDisplayConductOutreachButton && classes.dialogActions) || ""
        }
      >
        <MuiButton
          onClick={() => handleClearAlert()}
          disabled={!outreachFailureSummaryReport.length}
          color="primary"
        >
          {t(`OutreachFailureSummary.clearAlertLabel`)}
        </MuiButton>
        {isDisplayConductOutreachButton && (
          <Link
            to={{
              pathname: `/outreach/${studyId}/conduct`,
            }}
          >
            <MuiButton color="primary">
              {t(`OutreachFailureSummary.goToConductOutreachLabel`)}
            </MuiButton>
          </Link>
        )}
        <MuiButton onClick={onClose} color="primary">
          {t(`Close`)}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default OutreachFailureSummary;
