import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Divider as MuiDivider,
  TextField as MuiTextField,
  MenuItem as MuiMenuItem,
  Button as MuiButton,
  InputLabel as MuiInputLabel,
  Select as MuiSelect,
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import * as Yup from "yup";
import { useAlerts } from "common";
import { Formik, Form } from "formik";
import { useStyles } from "app/prospect/EditProspectPageStyles";
import PEMail from "app/shared/UI/PEMail";
import AntSwitch from "app/shared/UI/AntSwitch";
import { OptOutReason } from "app/shared/constants";
import {
  getProspectByMrn,
  editProspectById,
} from "app/services/prospectServices";
import { differenceObject } from "app/shared/utils";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import ProspectDetailsOutreachHistory from "app/prospect/ProspectDetailsOutreachHistory";
import ProspectDetailsCohortHistory from "app/prospect/ProspectDetailsCohortHistory";

const EditProspectPageContent = (props) => {
  const { state = { prospect: { emails: [], phones: [], addresses: [] } } } =
    props.location;
  const { prospectId } = props.match.params;
  const { setAlert } = useAlerts();
  const classes = useStyles();
  const { t } = useTranslation();
  const { prospect } = state;
  const history = useHistory();

  const [prospectData, setProspectData] = useState(prospect);
  const { emails = [], phones = [], addresses = [] } = prospectData;

  const [optedStatus, setOptedStatus] = useState(
    prospectData.optOut ? true : false
  );

  useEffect(() => {
    getProspectByMrn(prospectId, setProspectData, setAlert, setOptedStatus);
  }, [prospectId, setAlert]);

  const [openASConfirmation, setOpenASConfirmation] = useState(false);

  const formValidationSchema = Yup.object().shape({
    optOut: Yup.object()
      .shape({
        optOutReason: Yup.string()
          .required(t("formValidation.optOutReasonRequired"))
          .nullable(),
        optOutDate: Yup.date()
          .typeError(t("formValidation.invalidDateFormat"))
          .required(t("formValidation.optOutDateRequired"))
          .nullable(),
        optOutComment: Yup.string().required(
          t("formValidation.optOutCommentRequired")
        ),
      })
      .nullable()
      .default(undefined),
    concern: Yup.object()
      .shape({
        concernDate: Yup.date()
          .typeError(t("formValidation.invalidDateFormat"))
          .required(t("formValidation.concernDateReq"))
          .nullable(),
        concernDescription: Yup.string()
          .required(t("formValidation.concernReq"))
          .nullable(),
      })
      .nullable()
      .default(undefined),
  });
  const diff1 = (values) => differenceObject(values, prospectData);

  const handleClickCancel = (dirty) => {
    if (dirty) {
      setOpenASConfirmation(true);
    } else {
      history.push("/prospects/lookup");
    }
  };
  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);
    if (cancelChoice) {
      history.push("/prospects/lookup");
    }
  };

  const getValueSeparator = (value, type) => {
    if (type === "email") {
      return value.map((row, idx) => (
        <MuiTypography key={idx} variant="subtitle1">
          <PEMail emailAddress={row}>{(idx ? ", " : "") + row}</PEMail>
        </MuiTypography>
      ));
    }
    return (
      <MuiTypography variant="subtitle1">{value.join(", ")}</MuiTypography>
    );
  };

  return (
    <div className={classes.root}>
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        message={t(`OutreachDetails.confirmationMessageCancel`)}
        okLabel={t("ConductOutreachPage.yesLabel")}
        cancelLabel={t("ConductOutreachPage.noLabel")}
      />
      <MuiGrid container spacing={3}>
        <MuiGrid item xs={6}>
          <MuiPaper className={classes.paper}>
            <MuiTypography>{t("LookupProspect.prospectDetails")}</MuiTypography>
            <MuiGrid container item xs={12} className={classes.customPadding}>
              <MuiGrid item xs={3}>
                {t("LookupProspect.tableColumnMRN")}:
              </MuiGrid>
              <MuiGrid item xs={9}>
                <MuiTypography variant="subtitle1">
                  {prospectData.mrn}
                </MuiTypography>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container item xs={12} className={classes.customPadding}>
              <MuiGrid item xs={3}>
                {t("LookupProspect.tableColumnFirstName")}:
              </MuiGrid>
              <MuiGrid item xs={9}>
                <MuiTypography variant="subtitle1">
                  {prospectData.firstName}
                </MuiTypography>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container item xs={12} className={classes.customPadding}>
              <MuiGrid item xs={3}>
                {t("LookupProspect.tableColumnLastName")}:
              </MuiGrid>
              <MuiGrid item xs={9}>
                <MuiTypography variant="subtitle1">
                  {prospectData.lastName}
                </MuiTypography>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container item xs={12} className={classes.customPadding}>
              <MuiGrid item xs={3}>
                {t("LookupProspect.tableColumnEmail")}:
              </MuiGrid>
              <MuiGrid container item xs={9}>
                {getValueSeparator(emails, "email")}
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container item xs={12} className={classes.customPadding}>
              <MuiGrid item xs={3}>
                {t("LookupProspect.tableColumnPhone")}:
              </MuiGrid>
              <MuiGrid container item xs={9}>
                {getValueSeparator(phones)}
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container item xs={12} className={classes.customPadding}>
              <MuiGrid item xs={3}>
                {t("LookupProspect.tableColumnDOB")}:
              </MuiGrid>
              <MuiGrid item xs={9}>
                <MuiTypography variant="subtitle1">
                  {prospectData.dob}
                </MuiTypography>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container item xs={12} className={classes.customPadding}>
              <MuiGrid item xs={3}>
                {t("LookupProspect.tableColumnAddress")}:
              </MuiGrid>
              <MuiGrid item xs={9}>
                {addresses.map((row, idx) => (
                  <MuiTypography key={idx} variant="subtitle1">
                    {row}
                    <MuiDivider orientation="horizontal" />
                  </MuiTypography>
                ))}
              </MuiGrid>
            </MuiGrid>
          </MuiPaper>
        </MuiGrid>
        <MuiGrid item xs={6}>
          <Formik
            initialValues={prospectData}
            enableReinitialize={true}
            validationSchema={formValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              editProspectById(
                prospectId,
                diff1(values),
                setAlert,
                setProspectData,
                history
              );
            }}
          >
            {({
              values,
              isValid,
              dirty,
              isSubmitting,
              handleBlur,
              setFieldValue,
              setValues,
              errors,
              touched,
            }) => {
              return (
                <Form>
                  <MuiPaper className={classes.paper}>
                    <MuiTypography>
                      {t("LookupProspect.editProspect")}
                    </MuiTypography>

                    <MuiGrid
                      container
                      item
                      xs={12}
                      className={classes.customPadding}
                    >
                      <MuiGrid item xs={3}>
                        {t("LookupProspect.optedStatus")}
                      </MuiGrid>
                      <MuiTypography component="div">
                        <MuiGrid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <MuiGrid item>
                            <MuiTypography variant="subtitle1">
                              In
                            </MuiTypography>
                          </MuiGrid>
                          <MuiGrid item>
                            <AntSwitch
                              name="optedStatus"
                              checked={optedStatus}
                              onChange={(evt) => {
                                setOptedStatus(evt.target.checked);
                                if (evt.target.checked) {
                                  setFieldValue(
                                    `optOut[optOutDate]`,
                                    new Date().toISOString()
                                  );
                                } else {
                                  setFieldValue(`optOut`, null);
                                }
                              }}
                            />
                          </MuiGrid>
                          <MuiGrid item>
                            <MuiTypography variant="subtitle1">
                              Out
                            </MuiTypography>
                          </MuiGrid>
                        </MuiGrid>
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid item xs={12} className={classes.customTopPadding}>
                      <MuiFormControl
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        error={
                          errors.optOut &&
                          touched.optOutReason &&
                          Boolean(errors.optOut.optOutReason)
                        }
                        className={classes.selectMarginDense}
                      >
                        <MuiInputLabel
                          variant="outlined"
                          required={optedStatus}
                          disabled={!optedStatus}
                          id="optOutReason"
                          shrink
                        >
                          {t("LookupProspect.optOutReason")}
                        </MuiInputLabel>
                        <MuiSelect
                          label={t("LookupProspect.optOutReason")}
                          labelId="optOutReason"
                          displayEmpty
                          onBlur={handleBlur}
                          value={
                            values.optOut && values.optOut.optOutReason
                              ? values.optOut.optOutReason
                              : ""
                          }
                          disabled={!optedStatus}
                          onChange={(evt) => {
                            setFieldValue(
                              `optOut[optOutReason]`,
                              evt.target.value
                            );
                          }}
                          fullWidth
                          name="optOutReason"
                          notched
                        >
                          <MuiMenuItem value={""}>
                            {t("formLabel.none")}
                          </MuiMenuItem>
                          {OptOutReason.map((item) => (
                            <MuiMenuItem key={item} value={item}>
                              {t("LookupProspect." + item)}
                            </MuiMenuItem>
                          ))}
                        </MuiSelect>
                        <MuiFormHelperText>
                          {errors &&
                            touched.optOutReason &&
                            errors.optOut?.optOutReason}
                        </MuiFormHelperText>
                      </MuiFormControl>
                    </MuiGrid>
                    <MuiGrid
                      container
                      className={classes.customTopPadding}
                      justify="space-between"
                    >
                      <MuiGrid item xs={7}>
                        <MuiTextField
                          name="optOutComment"
                          label={t("LookupProspect.optOutComment")}
                          variant="outlined"
                          multiline={true}
                          onBlur={handleBlur}
                          disabled={!optedStatus}
                          fullWidth
                          size="small"
                          value={
                            values.optOut && values.optOut.optOutComment
                              ? values.optOut.optOutComment
                              : ""
                          }
                          onChange={(evt) => {
                            setFieldValue(
                              `optOut[optOutComment]`,
                              evt.target.value
                            );
                          }}
                          error={
                            errors.optOut &&
                            touched.optOutComment &&
                            Boolean(errors.optOut.optOutComment)
                          }
                          helperText={
                            errors &&
                            touched.optOutComment &&
                            errors.optOut?.optOutComment
                          }
                        />
                      </MuiGrid>
                      <MuiGrid item xs={4} className={classes.datePicker}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <MuiKeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            label={t("LookupProspect.optOutDate")}
                            format="MM/dd/yyyy"
                            onBlur={handleBlur}
                            margin="dense"
                            disabled={!optedStatus}
                            value={values.optOut && values.optOut.optOutDate}
                            error={
                              errors.optOut && Boolean(errors.optOut.optOutDate)
                            }
                            helperText={errors && errors.optOut?.optOutDate}
                            onChange={(date, value) => {
                              const checkDate = Date.parse(date);

                              if (!isNaN(checkDate)) {
                                setFieldValue(
                                  `optOut[optOutDate]`,
                                  date.toISOString()
                                );
                              } else {
                                setFieldValue(`optOut[optOutDate]`, value);
                              }
                            }}
                            id="optOutDate"
                            name="optOutDate"
                            fullWidth
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MuiPickersUtilsProvider>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      container
                      className={classes.customTopPadding}
                      justify="space-between"
                    >
                      <MuiGrid item xs={7}>
                        <MuiTextField
                          name="concern"
                          label={t("LookupProspect.concern")}
                          variant="outlined"
                          multiline={true}
                          onBlur={handleBlur}
                          fullWidth
                          size="small"
                          value={
                            values.concern && values.concern.concernDescription
                          }
                          error={
                            errors.concern &&
                            touched.concernDescription &&
                            Boolean(errors.concern.concernDescription)
                          }
                          helperText={
                            errors &&
                            touched.concernDescription &&
                            errors.concern?.concernDescription
                          }
                          onChange={(evt) => {
                            const newData = evt.target.value
                              ? {
                                  ...values.concern,
                                  concernDescription: evt.target.value,
                                  concernDate: format(new Date(), "yyyy-MM-dd"),
                                }
                              : null;

                            setValues((prevValues) => ({
                              ...prevValues,
                              // we use the name to tell Formik which key of `values` to update
                              concern: newData,
                            }));
                          }}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={4} className={classes.datePicker}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <MuiKeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            label={t("LookupProspect.concernDate")}
                            format="MM/dd/yyyy"
                            onBlur={handleBlur}
                            margin="dense"
                            value={
                              values.concern && values.concern.concernDate
                                ? values.concern.concernDate
                                : null
                            }
                            disabled={!values.concern}
                            error={
                              errors.concern &&
                              Boolean(errors.concern.concernDate)
                            }
                            helperText={errors && errors.concern?.concernDate}
                            onChange={(date, value) => {
                              const checkDate = Date.parse(date);

                              if (!isNaN(checkDate)) {
                                setFieldValue(
                                  `concern[concernDate]`,
                                  format(new Date(date), "yyyy-MM-dd")
                                );
                              } else {
                                setFieldValue(`concern[concernDate]`, value);
                              }
                            }}
                            id="concernDate"
                            name="concernDate"
                            fullWidth
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MuiPickersUtilsProvider>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item xs={12} className={classes.customTopPadding}>
                      <MuiTextField
                        name="notes"
                        label={t("LookupProspect.notes")}
                        variant="outlined"
                        multiline={true}
                        onBlur={handleBlur}
                        fullWidth
                        size="small"
                        value={values.note}
                        onChange={(evt) => {
                          setFieldValue("note", evt.target.value);
                        }}
                      />
                    </MuiGrid>
                    <MuiGrid
                      container
                      justify="flex-end"
                      className={classes.customPadding}
                    >
                      <MuiGrid container justify="flex-end" item xs={2}>
                        <MuiButton
                          variant="outlined"
                          onClick={() => handleClickCancel(dirty)}
                        >
                          {t("formLabel.buttonCancel")}
                        </MuiButton>
                      </MuiGrid>
                      <MuiGrid container justify="flex-end" item xs={2}>
                        <MuiButton
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={
                            !isValid ||
                            !dirty ||
                            isSubmitting ||
                            !Object.keys(diff1(values)).length > 0
                          }
                        >
                          {t("formLabel.submitButtonLabel")}
                        </MuiButton>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiPaper>
                </Form>
              );
            }}
          </Formik>
        </MuiGrid>
        <MuiGrid item xs={12} className={classes.gridContainer}>
          <MuiTypography variant="h6">
            {t(`ProspectDetailsCohortHistory.cohortHistoryLabel`)}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <ProspectDetailsCohortHistory mrn={prospectId} />
        </MuiGrid>
        <MuiGrid item xs={12} className={classes.gridContainer}>
          <MuiTypography variant="h6">
            {t(`ProspectDetailsOutreachHistory.outreachHistoryLabel`)}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <ProspectDetailsOutreachHistory mrn={prospectId} />
        </MuiGrid>
      </MuiGrid>
    </div>
  );
};

export default EditProspectPageContent;
