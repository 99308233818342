import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box as MuiBox,
  Button as MuiButton,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Typography as MuiTypography,
  Tooltip as MuiTooltip,
} from "@material-ui/core";
import { ErrorOutline as MuiErrorOutlineIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useAlerts, useUsers } from "common";
import isEmpty from "lodash/isEmpty";
import { useStyles } from "app/study/CreateEditStudyFormStyles";
import { useStudies } from "app/services/studiesService";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import {
  GET_OUTREACH_CONFIGURATION_TEMPLATES,
  GET_STUDIES_URL_BASE,
  HONEST_BROKER_ASSIGNMENT_STATUS,
  RecruitmentPreferences,
  StudyContactRole,
  TokenType,
} from "app/shared/constants";
import {
  addTwoYearsToTheCurrentDate,
  mapStudyToDisplayed,
  marshallTemplates,
  removeTzFromLocalDatetime,
} from "app/shared/utils";
import CreateEditStudyMain from "app/study/CreateEditStudyMain";
import StudyContactsTab from "app/study/StudyContactsTab";
import StudyDetailsTab from "app/study/StudyDetailsTab";
import StudyPreferencesTab from "app/study/StudyPreferencesTab";
import StudyTargetAccrualsTab from "./StudyTargetAccrualsTab";
import TemplatesConfiguration from "app/study/TemplatesConfiguration";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { isValid } from "date-fns";

// component to display tab header
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <MuiTypography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      {...other}
    >
      {value === index && <MuiBox m={3}>{children}</MuiBox>}
    </MuiTypography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CreateEditStudyForm = () => {
  const { studyId } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentUser } = useUsers();

  const [activeTab, setActiveTab] = useState(0);

  const [initData, setInitData] = useState({});
  const [isInitDataProcessed, setIsInitDataProcessed] = useState(false);
  const [templatesData, setTemplatesData] = useState({});
  const { studies, setStudies, benchmarks, allHonestBrokers } = useStudies();

  const [isOptimizationEnabled, setIsOptimizationEnabled] = useState(
    initData.targetAccruals
      ? initData.targetAccruals.isOptimizationEnabled
      : benchmarks?.isOptimizationEnabled
  );

  const [openASConfirmation, setOpenASConfirmation] = useState(false);

  const [hasTemplateTooltipErr, setTemplateTooltipErr] = useState(false);

  const { setAlert } = useAlerts();
  const { studyValidationErrors } = initData;

  const currentUserHonestBroker = (allHonestBrokers, currentUser) => {
    if (allHonestBrokers && allHonestBrokers.length > 0) {
      const result = allHonestBrokers.find(
        (contact) =>
          contact.currentHonestBroker && contact.userName === currentUser.uid
      );
      return result;
    }
    return null;
  };

  useEffect(() => {
    if (studyId) {
      (async () => {
        try {
          // fetch study by id
          const response = await axios.get(
            GET_STUDIES_URL_BASE + "/" + studyId
          );

          const mappedStudy = mapStudyToDisplayed(
            response.data,
            currentUserHonestBroker(allHonestBrokers, currentUser)
          );

          const followUpDate = isValid(mappedStudy.followUpDate)
            ? new Date(mappedStudy.followUpDate)
            : new Date(Date.now() + 12096e5);
          const recruitmentStartDate =
            mappedStudy.recruitmentStartDate ?? new Date(Date.now());
          const recruitmentEndDate =
            mappedStudy.recruitmentEndDate ?? addTwoYearsToTheCurrentDate();

          setInitData({
            ...mappedStudy,
            followUpDate,
            recruitmentStartDate,
            recruitmentEndDate,
          });
          setIsOptimizationEnabled(
            mappedStudy.targetAccruals?.isOptimizationEnabled
          );
        } catch (err) {
          setAlert("error", err.message);
          if (!err.response) {
            err.response = {};
          }
          err.response.errorProcessed = true;
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, setAlert, studyId]);

  useEffect(() => {
    if (studyId && Object.keys(initData).length > 0 && !isInitDataProcessed) {
      (async () => {
        try {
          setIsInitDataProcessed(true);

          //  fetch outreach configuration templates
          const templateResponse = await axios.get(
            GET_OUTREACH_CONFIGURATION_TEMPLATES
          );

          setTemplatesData({
            outreachTemplates: templateResponse.data.templates,
            studyTemplates: initData.studyTemplates,
          });

          const mappedStudy = marshallTemplates(
            initData,
            templateResponse.data.templates
          );

          setInitData({
            ...mappedStudy,
          });
        } catch (err) {
          setAlert("error", err.message);
          setTemplateTooltipErr(true);
          if (!err.response) {
            err.response = {};
          }
          err.response.errorProcessed = true;
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId, initData, setAlert]);

  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);
    if (cancelChoice) {
      history.push("/");
    }
  };

  const transformTimezone = (values) => {
    if (values.followUpDate) {
      values.followUpDate = removeTzFromLocalDatetime(values.followUpDate);
    }
    if (values.recruitmentStartDate) {
      values.recruitmentStartDate = removeTzFromLocalDatetime(
        values.recruitmentStartDate
      );
    }
    if (values.recruitmentEndDate) {
      values.recruitmentEndDate = removeTzFromLocalDatetime(
        values.recruitmentEndDate
      );
    }

    if (values.targetAccruals && values.targetAccruals.lastUpdated) {
      values.targetAccruals.lastUpdated = removeTzFromLocalDatetime(
        values.targetAccruals.lastUpdated
      );
    }
    return values;
  };

  const saveAndCreateUpdateStudy = (formikValues) => {
    const honestBroker = {
      id: formikValues.honestBroker.id ?? formikValues.honestBroker,
    };
    const newDataDropTz = {
      ...transformTimezone(formikValues),
      honestBroker,
    };
    newDataDropTz.targetAccruals = newDataDropTz.targetAccruals || {
      isOptimizationEnabled: isOptimizationEnabled,
    };

    const studyTemplateData = newDataDropTz.studyTemplates.filter(
      (template) =>
        template.hasOwnProperty("getStudyTemp") ||
        template.hasOwnProperty("toRemoveInPatch")
    );

    studyTemplateData.forEach((template) => {
      delete template.getStudyTemp;
      template.initialGetStudyTemp && delete template.initialGetStudyTemp;
    });

    const studyTemplates = studyTemplateData.filter(
      (template) => !template.hasOwnProperty("toRemoveInPatch")
    );

    updateStudy({
      ...newDataDropTz,
      studyTemplates: studyTemplates.length > 0 ? studyTemplates : undefined,
    });
  };

  const updateStudy = async (newData) => {
    try {
      const apiHeader = {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      };

      const response = await axios.patch(
        GET_STUDIES_URL_BASE + "/" + newData.id,
        newData,
        apiHeader
      );

      // index represents the sorted position of the selected study
      // before entering the CreateEditStudyForm
      let index = -1;
      if (state) {
        index = state.index;
      } else {
        // come to the page by refreshing
        index = studies.findIndex((studyObj) => studyObj.id === newData.id);
      }
      // after success save, navigate back to studydashboard
      history.push({ pathname: "/", highlightRow: index });
      // update existing study in study dashboard with changes
      const dataIndex = studies.findIndex(
        (studyObj) => studyObj.id === newData.id
      );
      const newStudies = [...studies];
      newStudies[dataIndex] = response.data;
      setStudies(newStudies);
    } catch (err) {
      setAlert("error", err.message);
    }
  };

  const nameToIndexMap = RecruitmentPreferences.reduce((acc, name, index) => {
    acc[name] = index;
    return acc;
  }, {});

  // sort studyReference based on the index from RecruitmentPreferences
  initData?.recruitmentPreferences?.sort((a, b) => {
    const indexA =
      nameToIndexMap[a.name] !== undefined ? nameToIndexMap[a.name] : Infinity;
    const indexB =
      nameToIndexMap[b.name] !== undefined ? nameToIndexMap[b.name] : Infinity;

    return indexA - indexB;
  });

  const studyValidationSchema = Yup.object().shape({
    // MAIN TAB
    title: Yup.string().required(),
    followUpDate: Yup.date()
      .required(t("formValidation.followUpDateErrReq"))
      .typeError(t("formValidation.validDate")),
    honestBrokerRecruitmentStatus: Yup.string().notOneOf(["UNINITIATED"]),

    // DETAILS TAB
    division: Yup.string().required(),
    honestBroker: Yup.object().shape({
      id: Yup.string().required(),
    }),
    recruitmentStartDate: Yup.date().nullable().required(),
    recruitmentEndDate: Yup.date()
      .nullable()
      .required()
      .test(
        "end-after-start",
        t("formValidation.recruitmentStartEndDate"),
        function (value) {
          const { recruitmentStartDate } = this.parent;
          if (
            recruitmentStartDate &&
            value &&
            value.getTime() < recruitmentStartDate.getTime()
          ) {
            return false;
          }
          return true;
        }
      ),

    // CONTACT TAB
    recruitmentEmail: Yup.string()
      .email(t("formValidation.invalidEmail"))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t("formValidation.invalidEmail")
      ),
    recruitmentPhone: Yup.string().matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    ),
    studyContacts: Yup.array()
      .of(
        Yup.object().shape({
          role: Yup.string().required(),
          leadCrc: Yup.boolean().required(),
        })
      )
      .test("single-lead-crc", function (value) {
        const leadCrcs = value?.filter(
          (contact) => contact.role === StudyContactRole.PRIMARY_CRC
        );

        const mainCrcs = value?.filter(
          (contact) =>
            contact.role === StudyContactRole.PRIMARY_CRC &&
            contact.leadCrc === true
        );

        if (leadCrcs.length > 0 && mainCrcs.length !== 1) {
          // scroll to the bottom on error, simulating the existing functionality
          window.scroll({
            top: document.body.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
          return false;
        }
        return true;
      }),
    // EDIT TOKENS TAB
    studyTemplates: Yup.array().of(
      Yup.object().shape({
        tokens: Yup.array()
          .of(
            Yup.object().shape({
              value: Yup.string()
                .when("type", {
                  is: (type) =>
                    type === TokenType.TEXT || type === TokenType.RICH_TEXT,
                  then: () => Yup.string().min(1).required(),
                  otherwise: Yup.string().notRequired(),
                })
                .when("type", {
                  is: TokenType.NUMBER,
                  then: () => Yup.number().required().typeError(),
                }),
            })
          )
          .required("Tokens are required"),
      })
    ),
    // PREFERENCES TAB
    recruitmentPreferences: Yup.array().of(
      Yup.object().shape({
        value: Yup.lazy((value, { parent }) => {
          switch (parent.name) {
            case "OR_ENABLE_CHANNELS":
            case "CH_INCLUDE_ENTITIES":
            case "OR_NO_CONTACT_PROSPECT_WITHIN_X_DAYS":
            case "OR_PROSPECT_ACCOUNT_LAST_ACCESS_WITHIN_X_DAYS":
            case "OR_STUDY_ONBOARDING_CAPACITY":
              if (parent.name === "OR_STUDY_ONBOARDING_CAPACITY") {
                return Yup.string()
                  .required()
                  .test(
                    "is-valid-value",
                    t("formValidation.preferenceOnboardingCapacity"),
                    (value) => {
                      const regex = /^[0-9\b]+$/;
                      const numericValue = value ? Number(value) : NaN;

                      // check if the value matches the regex and is within the number range
                      return (
                        regex.test(value) &&
                        !isNaN(numericValue) &&
                        numericValue >= 1 &&
                        numericValue <= 999999
                      );
                    }
                  );
              }
              return Yup.string()
                .required()
                .test(
                  "is-valid-value",
                  (value) => value && value.trim() !== ""
                );
            default:
              return Yup.string();
          }
        }),
      })
    ),
  });

  const checkTargetNotEqualToOne = (categoriesArr) => {
    for (let object of categoriesArr) {
      if (object.totals.target !== 1) {
        return true;
      }
    }
    return false;
  };

  return !isEmpty(initData) ? (
    <MuiGrid container className={classes.root}>
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        title={t("cancelConfirmation.title")}
        message={t("cancelConfirmation.message")}
        okLabel={t("cancelConfirmation.okLabel")}
        cancelLabel={t("cancelConfirmation.cancelLabel")}
      />
      <MuiGrid item className={classes.customGridMargin} xs={12}>
        <MuiTypography variant="h5" noWrap>
          {initData.honestBrokerStatus ===
          HONEST_BROKER_ASSIGNMENT_STATUS.NOT_ASSIGNED
            ? t(`pageTitle.assignHb`)
            : t(`pageTitle.editStudy`)}
        </MuiTypography>
      </MuiGrid>

      {Object.keys(studyValidationErrors).length > 0 && (
        <MuiGrid
          item
          className={classes.customGridMargin}
          style={{ backgroundColor: "#FCECEA", padding: "16px" }}
          xs={12}
        >
          <MuiTypography
            variant="h6"
            component="div"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            {t("formLabel.sourceDataErr")}
          </MuiTypography>
          {Object.keys(studyValidationErrors).map((errorArray) => {
            return studyValidationErrors[errorArray]?.values?.map(
              (errorMsg) => (
                <MuiGrid className={classes.errorMsgGrid}>
                  <MuiErrorOutlineIcon
                    color="secondary"
                    className={classes.errorOutlineIcon}
                  />
                  <MuiTypography
                    className={classes.errorMessage}
                    variant="body2"
                    component="p"
                    key={errorMsg}
                  >
                    {errorMsg}
                  </MuiTypography>
                </MuiGrid>
              )
            );
          })}
        </MuiGrid>
      )}

      <MuiGrid item className={classes.customGridMargin} xs={12}>
        <MuiTypography>
          {"IRB#: "}{" "}
          <MuiTypography variant="subtitle1" display="inline">
            {initData.irbNumber}
          </MuiTypography>
        </MuiTypography>
      </MuiGrid>
      <MuiGrid item className={classes.customGridMargin} xs={12}>
        <Formik
          enableReinitialize
          initialValues={initData}
          validationSchema={studyValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            saveAndCreateUpdateStudy(values);

            setSubmitting(false);
          }}
        >
          {({ values, errors, dirty, isValid, isSubmitting }) => {
            return (
              <Form>
                <CreateEditStudyMain
                  setIsOptimizationEnabled={setIsOptimizationEnabled}
                />
                <MuiPaper className={classes.tabHeader}>
                  <MuiTabs
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(evt, tabNewValue) => setActiveTab(tabNewValue)}
                  >
                    <MuiTab label={t("tabsLabel.details")} />
                    <MuiTab label={t("tabsLabel.contacts")} />
                    <MuiTab label={t("tabsLabel.preference")} />
                    {values && !values?.protocolSummaryAccrual ? (
                      <MuiTab label={t("tabsLabel.targetAccruals")} />
                    ) : (
                      ""
                    )}
                    {hasTemplateTooltipErr ? (
                      <MuiTooltip
                        title={t("tabsLabel.templateErrTooltip")}
                        arrow
                        disableInteractive
                        PopperProps={{
                          disableInteractive: true,
                        }}
                      >
                        <span>
                          <MuiTab
                            label={t("tabsLabel.emailTemplatesConfiguration")}
                            disabled
                          />
                        </span>
                      </MuiTooltip>
                    ) : (
                      <MuiTab
                        label={t("tabsLabel.emailTemplatesConfiguration")}
                      />
                    )}
                  </MuiTabs>
                </MuiPaper>
                <TabPanel
                  className={classes.scrollableContainer}
                  value={activeTab}
                  index={0}
                >
                  <StudyDetailsTab />
                </TabPanel>
                <TabPanel
                  className={classes.scrollableContainer}
                  value={activeTab}
                  index={1}
                >
                  <StudyContactsTab />
                </TabPanel>
                <TabPanel
                  className={classes.scrollableContainer}
                  value={activeTab}
                  index={2}
                >
                  <StudyPreferencesTab />
                </TabPanel>
                {initData && !initData?.protocolSummaryAccrual && (
                  <TabPanel value={activeTab} index={3}>
                    <StudyTargetAccrualsTab
                      isOptimizationEnabled={isOptimizationEnabled}
                    />
                  </TabPanel>
                )}

                <TabPanel
                  className={classes.scrollableContainer}
                  value={activeTab}
                  index={initData && !initData?.protocolSummaryAccrual ? 4 : 3}
                >
                  <TemplatesConfiguration templatesData={templatesData} />
                </TabPanel>

                {/* BUTTON */}
                <MuiGrid
                  container
                  spacing={3}
                  item
                  xs={12}
                  justifyContent="flex-end"
                >
                  {Object.keys(studyValidationErrors).length > 0 ? (
                    <MuiGrid container item xs={1} justifyContent="flex-end">
                      <MuiButton
                        variant="outlined"
                        onClick={() => history.push("/")}
                      >
                        {t(`formLabel.Close`)}
                      </MuiButton>
                    </MuiGrid>
                  ) : (
                    <>
                      <MuiGrid container item xs={1} justifyContent="flex-end">
                        <MuiButton
                          variant="outlined"
                          onClick={() => {
                            if (dirty || Object.keys(errors).length > 0) {
                              setOpenASConfirmation(true);
                            } else {
                              history.push("/");
                            }
                          }}
                        >
                          {t(`formLabel.buttonCancel`)}
                        </MuiButton>
                      </MuiGrid>
                      <MuiGrid container item xs={1} justifyContent="flex-end">
                        <MuiButton
                          variant="contained"
                          type="submit"
                          color="primary"
                          disabled={
                            !isValid ||
                            !dirty ||
                            isSubmitting ||
                            Object.keys(errors).length > 0 ||
                            checkTargetNotEqualToOne(
                              values.targetAccruals.categories
                            )
                          }
                        >
                          {t(`formLabel.buttonSave`)}
                        </MuiButton>
                      </MuiGrid>
                    </>
                  )}
                </MuiGrid>
              </Form>
            );
          }}
        </Formik>
      </MuiGrid>
    </MuiGrid>
  ) : null;
};

export default CreateEditStudyForm;
