import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NumericFormat from "react-number-format";
import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TextField as MuiTextField,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  Typography as MuiTypography,
  Checkbox as MuiCheckbox,
  Tooltip as MuiToolTip,
} from "@material-ui/core";
import { Delete as MuiDeleteIcon } from "@material-ui/icons";
import { useFormikContext } from "formik";
import { Theme, useAlerts } from "common";
import {
  GET_STUDIES_URL_BASE,
  TokensLabelMapping,
  TokenType,
} from "app/shared/constants";
import { combineData, sortByTemplateName } from "app/shared/utils";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import RichTextEditor from "./RichTextEditor";
import { TemplatesConfigRoot, useStyles } from "./TemplatesConfigStyles";

const headerCell = ["Token", "Value"];

const TemplatesConfiguration = ({ templatesData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [openASConfirmation, setOpenASConfirmation] = useState(false);
  const [openASConfirmationIndex, setOpenASConfirmationIndex] = useState(null);

  const { values, errors, handleBlur, touched, setFieldValue } =
    useFormikContext();

  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  const [selectedTempIndex, setSelectedTempIndex] = useState(0);

  const initCombinedData = templatesData.studyTemplates
    ? combineData(templatesData.outreachTemplates, templatesData.studyTemplates)
    : sortByTemplateName(templatesData.outreachTemplates ?? []);

  // function to check if there is an error for a specific tokens value in a study template
  const hasError = (templateIndex, tokenIndex) => {
    return (
      Object.keys(errors).length > 0 &&
      Object.keys(touched).length > 0 &&
      errors?.studyTemplates?.[templateIndex]?.tokens[tokenIndex]?.value &&
      touched?.studyTemplates?.[templateIndex]?.tokens[tokenIndex]?.value
    );
  };

  const closeCancelASConfirmation = (cancelChoice, template, tempInd) => {
    setOpenASConfirmation(false);
    // API call to cancel scheduled outreach will go here.
    if (cancelChoice) {
      (async () => {
        try {
          await axios.delete(
            `${GET_STUDIES_URL_BASE}/${values.id}/${template.templateId}`
          );

          const tempID = initCombinedData[tempInd].templateId;
          const ptemplate = templatesData.outreachTemplates.find(
            (item) => item.templateId === tempID
          );

          setFieldValue(`studyTemplates.[${tempInd}]`, ptemplate);
          setFieldValue(`studyTemplates.[${tempInd}].toRemoveInPatch`, true);
        } catch (err) {
          // set error message in AlertContext
          setAlert("error", err.message);
        }
      })();
    }
  };

  return (
    <MuiGrid container xs={12}>
      <MuiGrid item xs={3}>
        <MuiPaper item className={classes.root}>
          <MuiTypography
            variant="body"
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: Theme.palette.grey[700],
              color: Theme.palette.common.white,
              fontSize: "15px",
              padding: "5px 10px",
            }}
          >
            {t("templateConfigTable.templateCap")}
          </MuiTypography>
          <MuiDivider />
          <MuiList>
            {values.studyTemplates.map((template, tempInd) => (
              <>
                <MuiListItem
                  button
                  selected={selectedTempIndex === tempInd}
                  onClick={(event) => setSelectedTempIndex(tempInd)}
                >
                  <MuiToolTip
                    title={t("templateConfigTable.templateNameToolTip")}
                    arrow
                  >
                    <MuiListItemText
                      primary={template.templateName}
                      primaryTypographyProps={{ style: { fontSize: "15px" } }}
                    />
                  </MuiToolTip>

                  {template.getStudyTemp && (
                    <>
                      <MuiToolTip
                        title={t("templateConfigTable.trashToolTip")}
                        arrow
                      >
                        <MuiButton
                          onClick={(e) => {
                            if (
                              template.getStudyTemp &&
                              template.initialGetStudyTemp
                            ) {
                              setOpenASConfirmationIndex(tempInd);
                              setOpenASConfirmation(true);
                            } else {
                              setFieldValue(
                                `studyTemplates.[${tempInd}]`,
                                initCombinedData[tempInd]
                              );
                            }
                          }}
                        >
                          <MuiDeleteIcon />
                        </MuiButton>
                      </MuiToolTip>
                    </>
                  )}

                  <MuiToolTip
                    title={t("templateConfigTable.checkBoxToolTip")}
                    arrow
                  >
                    <MuiCheckbox
                      name={`studyTemplates`}
                      key={`studyTemplates.[${tempInd}].isDefault`}
                      id={`studyTemplates.[${tempInd}].isDefault`}
                      color="primary"
                      checked={!!template.isDefault}
                      disabled={!template.getStudyTemp}
                      onChange={() => {
                        return values.studyTemplates.forEach((temp, index) => {
                          if (tempInd !== index) {
                            setFieldValue(
                              `studyTemplates.[${index}].isDefault`,
                              false
                            );
                          } else {
                            setFieldValue(
                              `studyTemplates.[${index}].isDefault`,
                              !values.studyTemplates[index].isDefault
                            );
                          }
                        });
                      }}
                    />
                  </MuiToolTip>
                </MuiListItem>

                {openASConfirmationIndex === tempInd && (
                  <ConfirmationDialog
                    open={openASConfirmation}
                    onClose={(e) =>
                      closeCancelASConfirmation(e, template, tempInd)
                    }
                    message={t("templateConfigTable.deleteStudyConfirmMsg")}
                    okLabel={t("cancelConfirmation.okLabel")}
                    cancelLabel={t("cancelConfirmation.cancelLabel")}
                  />
                )}
              </>
            ))}
          </MuiList>
        </MuiPaper>
      </MuiGrid>

      <MuiGrid
        item
        xs={9}
        style={{
          paddingLeft: "16px",
        }}
      >
        {/* TEMPLATES STARTS */}
        <TemplatesConfigRoot>
          <MuiTableContainer>
            <MuiTable>
              <TemplatesConfigRoot.StudyTableHead>
                <TemplatesConfigRoot.StudyTableRow>
                  <MuiGrid container>
                    <MuiGrid
                      item
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      {headerCell.map((headCell) => {
                        return (
                          <MuiGrid
                            item
                            xs={6}
                            style={{
                              color: Theme.palette.common.white,
                              padding: "5px 10px",
                              paddingLeft: headCell === "Token" ? "14px" : "0",
                              fontSize: "15px",
                            }}
                          >
                            {headCell}
                          </MuiGrid>
                        );
                      })}
                    </MuiGrid>
                  </MuiGrid>
                </TemplatesConfigRoot.StudyTableRow>
              </TemplatesConfigRoot.StudyTableHead>

              {/* TABLE BODY */}
              <MuiTableBody>
                {/* selectedTempIndex */}
                {values.studyTemplates.length > 0 ? (
                  values.studyTemplates[selectedTempIndex] &&
                  values.studyTemplates[selectedTempIndex]?.tokens?.map(
                    (perTemplate, tokenIndex) => {
                      return (
                        <MuiGrid key={tokenIndex}>
                          <MuiGrid
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              padding: "5px",
                            }}
                          >
                            <MuiGrid item xs={6}>
                              <MuiTypography
                                style={{
                                  padding: "5px 10px",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "15px",
                                }}
                              >
                                {TokensLabelMapping[perTemplate.name]
                                  ? TokensLabelMapping[perTemplate.name]
                                  : perTemplate.name}
                              </MuiTypography>
                            </MuiGrid>
                            <MuiGrid
                              item
                              xs={6}
                              style={{
                                width: "300px",
                              }}
                            >
                              {perTemplate.type === TokenType.NUMBER ? (
                                <NumericFormat
                                  fullWidth
                                  required
                                  id={`studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].name`}
                                  name={`studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`}
                                  variant="outlined"
                                  size="small"
                                  decimalScale={0}
                                  allowNegative={false}
                                  allowLeadingZeros={false}
                                  value={perTemplate.value}
                                  customInput={MuiTextField}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    style: { fontSize: 15 },
                                  }}
                                  onValueChange={(values) => {
                                    const newValue = values.floatValue ?? "";

                                    setFieldValue(
                                      `studyTemplates.[${selectedTempIndex}].getStudyTemp`,
                                      true
                                    );

                                    setFieldValue(
                                      `studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`,
                                      newValue
                                    );
                                  }}
                                  error={hasError(
                                    selectedTempIndex,
                                    tokenIndex
                                  )}
                                  helperText={
                                    hasError(selectedTempIndex, tokenIndex) &&
                                    t("formValidation.requiredField")
                                  }
                                />
                              ) : perTemplate.type === TokenType.RICH_TEXT ? (
                                <>
                                  <MuiGrid item>
                                    <MuiDialog
                                      maxWidth="md"
                                      open={
                                        openDialogIndex?.selectedTempIndex ===
                                          selectedTempIndex &&
                                        openDialogIndex?.tokenIndex ===
                                          tokenIndex
                                      }
                                      onClose={(event, reason) => {
                                        if (reason === "backdropClick") {
                                          // prevent closing on backdrop click
                                          return;
                                        }
                                        setOpenDialogIndex(false);
                                      }}
                                    >
                                      <MuiDialogTitle
                                        style={{
                                          padding: "10px 24px",
                                        }}
                                      >
                                        <MuiTypography
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {TokensLabelMapping[perTemplate.name]}
                                        </MuiTypography>
                                      </MuiDialogTitle>
                                      <MuiDialogContent
                                        style={{
                                          marginBottom: "10px",
                                          padding: "0px 15px 10px 15px",
                                          width: "850px",
                                        }}
                                      >
                                        <RichTextEditor
                                          setOpenDialog={setOpenDialogIndex}
                                          tokenIndex={tokenIndex}
                                          perTemp={perTemplate}
                                          selectedTempIndex={selectedTempIndex}
                                          setFieldValue={setFieldValue}
                                        />
                                      </MuiDialogContent>
                                    </MuiDialog>
                                  </MuiGrid>
                                  <MuiGrid>
                                    <MuiButton
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        setOpenDialogIndex({
                                          selectedTempIndex,
                                          tokenIndex,
                                        })
                                      }
                                    >
                                      {t("templateConfigTable.editToken")}
                                    </MuiButton>
                                  </MuiGrid>
                                </>
                              ) : (
                                <MuiGrid>
                                  <MuiTextField
                                    id={`studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`}
                                    name={`studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`}
                                    multiline
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onBlur={handleBlur}
                                    inputProps={{
                                      style: { fontSize: 15 },
                                    }}
                                    maxRows={4}
                                    value={perTemplate.value}
                                    onChange={(event) => {
                                      const newValue = event.target.value ?? "";
                                      setFieldValue(
                                        `studyTemplates.[${selectedTempIndex}].getStudyTemp`,
                                        true
                                      );

                                      setFieldValue(
                                        `studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`,
                                        newValue
                                      );
                                    }}
                                    error={hasError(
                                      selectedTempIndex,
                                      tokenIndex
                                    )}
                                    helperText={
                                      hasError(selectedTempIndex, tokenIndex) &&
                                      t("formValidation.requiredField")
                                    }
                                  />
                                </MuiGrid>
                              )}
                            </MuiGrid>
                          </MuiGrid>
                          <MuiDivider />
                        </MuiGrid>
                      );
                    }
                  )
                ) : (
                  <MuiGrid style={{ textAlign: "center" }}>
                    {t("templateConfigTable.noTemplateAvailable")}
                  </MuiGrid>
                )}
              </MuiTableBody>
            </MuiTable>
          </MuiTableContainer>
        </TemplatesConfigRoot>
      </MuiGrid>
    </MuiGrid>
  );
};

export default TemplatesConfiguration;
