import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import {
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import {
  CloudUpload as MuiCloudUploadIcon,
  Info as MuiInfoIcon,
} from "@material-ui/icons";
import { useAlerts } from "common";
import { useStyles } from "app/prospect/ImportProspectsPageStyles";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import PEErrorDialog from "app/shared/UI/PEErrorDialog";
import PEExport from "app/shared/UI/PEExport";
import {
  GET_IMPORTS_URL_BASE,
  GET_STUDIES_URL_BASE,
  ImportStatuses,
  SourceTypeOptions,
} from "app/shared/constants";
import {
  addReverseIndexToElements,
  mapStudyToDisplayed,
} from "app/shared/utils";

const mapStatus = (originalStatus) => {
  const lowercaseStatus = originalStatus.toLowerCase();
  return lowercaseStatus[0].toUpperCase() + lowercaseStatus.substring(1);
};

const errorSummaryContent = {
  primaryContent: "row",
  secondaryContent: "message",
};

const defaultPageNumber = 0;

const ImportProspectsPageContent = (props) => {
  const { intervalSetter } = props;
  const { state, pathname } = props.location;
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [openASConfirmation, setOpenASConfirmation] = useState(false);
  const [selectedFiles, setSelectdFiles] = useState([]);
  const [studyImports, setStudyImports] = useState([]);
  const refStudyImports = useRef(studyImports);
  const [study, setStudy] = useState(null);
  const [importSource, setImportSource] = useState("STARR_HBE");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImportRequest, setSelectedImportRequest] = useState(-1);
  //const [replaceObsolete, setReplaceObsolete] = useState(false);
  const replaceObsolete = false;
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const classes = useStyles();
  const [importIds, setImportIds] = useState([]);
  const [importFileNames, setImportFileNames] = useState([]);
  const [importFileDuplicate, setImportFileDuplicate] = useState(false);

  const maxUploadFileSizeInMb = 50;

  const protocolNumber = study
    ? study.protocolNumber
    : state.study.protocolNumber;

  const postImportProspects = async () => {
    try {
      // call POST API to import chosen file
      const apiHeader = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // import a file of 50MB takes 80s, increase the timeout to accommodate (defined in .env)
        timeout: process.env.REACT_APP_IMPORT_UPLOAD_TIMEOUT,
      };

      const formData = new FormData();
      formData.append("file", selectedFiles[0]);

      //const irbNumber = study ? study.irbNumber : state.study.irbNumber;

      const postURL =
        GET_IMPORTS_URL_BASE +
        "/" +
        importSource +
        "/protocol/" +
        protocolNumber +
        "?obsolete-prospects=" +
        replaceObsolete;

      const response = await axios.post(postURL, formData, apiHeader);

      // reset import section after import successfully
      setSelectdFiles([]);
      // return import_reqest, should be added to the top of the Recent Activity list
      const newstudyImports = [...studyImports];
      newstudyImports.unshift(response.data);
      setStudyImports(newstudyImports);
      refStudyImports.current = newstudyImports;

      const ids = [...importIds, response.data.id];
      setImportIds(ids);
      const fileNames = [...importFileNames, response.data.filename];
      setImportFileNames(fileNames);

      // set a timer to retrieve the status of the import_request everty X seconds(defined in .env)
      // till the status become "Finished"
    } catch (err) {
      // set error.message in AlertContext
      setAlert("error", err.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!state) {
        // in case authorized user refresh the page, so lose the context that normally
        // will pass from study listing
        // pathname pattern is "/prospects/:studyId/import"
        const index1 = pathname.lastIndexOf("/");
        const studyId = pathname.substring(11, index1);

        // fetch study by id
        try {
          const response = await axios.get(
            `${GET_STUDIES_URL_BASE}/${studyId}`
          );

          setStudy(mapStudyToDisplayed(response.data)); // save this for comparison at edit save
        } catch (err) {
          setAlert("error", err.message); // set error.message in AlertContext
        }
      }
    })();
  }, [state, setAlert, pathname]);

  useEffect(() => {
    setTableLoader(true);

    (async () => {
      try {
        // fetch the import recent activities by protocolNumber
        if ((state && state.study) || study) {
          const response = await axios.get(
            `${GET_IMPORTS_URL_BASE}?protocolNumber=${protocolNumber}`,
            {
              timeout: process.env.REACT_APP_IMPORT_HISTORY_TIMEOUT,
            }
          );

          setStudyImports(response.data);
          refStudyImports.current = response.data;

          const fileNames = response.data?.map((row) => row.filename);
          setImportFileNames(fileNames);
        }
      } catch (err) {
        // set error.message in AlertContext
        setAlert("error", err.message);
      } finally {
        setTableLoader(false);
      }
    })();
  }, [state, study, setAlert, protocolNumber]);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectdFiles(acceptedFiles);
  }, []);

  const onDropRejected = useCallback(
    (fileRejections) => {
      setAlert(
        "error",
        fileRejections[0].errors
          .map((e) => e.code)
          .map((c) => {
            switch (c) {
              case "file-invalid-type":
                return t("ImportProspectsPage.importFileInvalidTypeMessage", {
                  validFileType: ".csv",
                });
              case "file-too-large":
                return t("ImportProspectsPage.importFileTooLargeMessage", {
                  maxFileSize: maxUploadFileSizeInMb,
                });
              default:
                return t("ImportProspectsPage.importFileGenericError");
            }
          })
          .join(", ")
      );
    },
    [t, setAlert]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    maxSize: maxUploadFileSizeInMb * 1024 * 1024,
    multiple: false,
    onDrop,
    onDropRejected,
  });

  const acceptedFilesItems = selectedFiles.map((file, index) => (
    <span key={index}>
      {file.path} - {file.size} bytes
    </span>
  ));

  const closeCancelASConfirmation = (okOnClose) => {
    setOpenASConfirmation(false);
    setImportFileDuplicate(false);
    if (okOnClose) {
      setLoader(true);
      // click confirmation OK button, proceed to Import
      postImportProspects();
    }
  };

  useEffect(() => {
    var timerVar = [];
    if (importIds.length) {
      //timerId is for associating every import with a unique id which will help us to clear that particular interval
      let timerId = {};
      let nStudyImports = [];
      timerVar = importIds.map((row, idx) => {
        //this is to update nStudyImports with studyImports at every mapping
        nStudyImports = [...refStudyImports.current];

        return setInterval(async () => {
          // call BE API to retrieve the import_request by id
          //this is to update nStudyImports with nStudyImports at every interval call to update multiple rows data simultaniously
          nStudyImports = [...nStudyImports];

          try {
            timerId[row] = timerVar[idx];
            const result = await axios.get(`${GET_IMPORTS_URL_BASE}/${row}`);

            const dataIndex = refStudyImports.current.findIndex(
              (studyImpObj) => studyImpObj.id === row
            );

            nStudyImports[dataIndex] = result.data;
            setStudyImports(nStudyImports);

            if (
              result.data.status === ImportStatuses.FINISHED ||
              result.data.status === ImportStatuses.FINISHED_WITH_ERROR ||
              result.data.status === ImportStatuses.FAILED
            ) {
              clearInterval(timerId[row]);
              //this is to manage every import file ids.
              importIds.shift(row);
              //this is to delete completed timerId from the set.
              delete timerId[row];
            }
          } catch (err) {
            // set error.message in AlertContext
            setAlert("error", err.message);
            clearInterval(timerId.row);
            importIds.shift(row);
            delete timerId[row];
          }
        }, process.env.REACT_APP_IMPORT_PROSPECTS_REFRESH_RATE);
      });

      timerVar.length > 0 && timerVar.map((id) => intervalSetter(id));
    }

    return () => {
      timerVar.length > 0 && timerVar.map((id) => clearInterval(id));
    };
  }, [importIds, intervalSetter, setAlert]);

  const handleClickReset = () => {
    setSelectdFiles([]);
  };

  const handleClickImport = () => {
    if (importFileNames.includes(selectedFiles[0].name))
      setImportFileDuplicate(true);
    setOpenASConfirmation(true);
  };

  const displayImportFailureDialog = (index) => {
    setOpenDialog(true);
    setSelectedImportRequest(index);
  };

  const closeImportFailureDialog = () => {
    setOpenDialog(false);
    setSelectedImportRequest(-1);
  };

  return study || (state && state.study) ? (
    <MuiGrid container>
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        title={t("ImportProspectsPage.confirmation_title")}
        message={
          importFileDuplicate
            ? t("ImportProspectsPage.duplicateImportMessage")
            : t("ImportProspectsPage.confirmation_message")
        }
        okLabel={t("ImportProspectsPage.confirmation_OK")}
        cancelLabel={t("ImportProspectsPage.confirmation_cancel")}
      />
      <MuiGrid container>
        <MuiGrid item xs={2} className={classes.gridContainer}>
          <MuiTypography>
            {t(`formLabel.irb`)}{" "}
            <MuiTypography variant="subtitle1" display="inline">
              {study ? study.irbNumber : state.study.irbNumber}
            </MuiTypography>
          </MuiTypography>
          <MuiTypography>
            {t(`formLabel.protocolNumber`)}: &nbsp;
            <MuiTypography variant="subtitle1" display="inline">
              {protocolNumber}
            </MuiTypography>
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={10} className={classes.gridContainer}>
          <MuiTypography>
            {`${t("formLabel.studyNickname")}: `}
            <MuiTypography variant="subtitle1" display="inline">
              {study ? study.nickname : state.study.nickname}
            </MuiTypography>
          </MuiTypography>
        </MuiGrid>
        <MuiGrid container className={classes.importSection}>
          <MuiGrid item xs={12}>
            <MuiTextField
              className={classes.formTextField}
              select
              label={t(`ImportProspectsPage.sourceTypeLabel`)}
              placeholder={t(`ImportProspectsPage.sourceTypeLabel`)}
              value={importSource}
              onChange={(evt) => setImportSource(evt.target.value)}
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            >
              {SourceTypeOptions.map((item) => (
                <MuiMenuItem key={item} value={item}>
                  {`${t("ProspectSourceType." + item)}`}
                </MuiMenuItem>
              ))}
            </MuiTextField>
          </MuiGrid>
          <MuiGrid item xs={12}>
            {/*<FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={replaceObsolete}
                  onChange={() => setReplaceObsolete(!replaceObsolete)}
                />
              }
              label={t(`ImportProspectsPage.replacePreviousLabel`)}
            />
            */}
          </MuiGrid>
          <div className={classes.dragDropContainer} {...getRootProps()}>
            <input {...getInputProps()} />
            <MuiCloudUploadIcon />
            <p>{t(`ImportProspectsPage.dragNDropFile`)}</p>
          </div>
          <MuiGrid container>
            {selectedFiles && selectedFiles.length > 0 && (
              <>
                <MuiGrid item xs={12} className={classes.textAlignCenter}>
                  <MuiTypography variant="subtitle1">
                    {t(`ImportProspectsPage.fileToUpload`)}&nbsp;
                    {acceptedFilesItems}
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid item xs={12}>
                  <div className={classes.importActionContainer}>
                    <div className={classes.wrapper}>
                      <MuiButton
                        variant="contained"
                        color="primary"
                        disabled={loader}
                        onClick={handleClickImport}
                      >
                        {t(`ImportProspectsPage.importButtonLabel`)}
                      </MuiButton>
                      {loader && (
                        <MuiCircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <MuiButton onClick={handleClickReset}>
                      {t(`ImportProspectsPage.resetButtonLabel`)}
                    </MuiButton>
                  </div>
                </MuiGrid>
              </>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container>
        <MuiGrid item xs={12} className={classes.titleContainer}>
          <MuiTypography variant="h2">
            {t(`ImportProspectsPage.importRecentActivities`)}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={12} className={classes.tableScroll}>
          <MuiTableContainer component={MuiPaper}>
            <MuiTable stickyHeader size="small">
              <MuiTableHead>
                <MuiTableRow>
                  <MuiTableCell>
                    {t(`ImportProspectsPage.tableColumnIndex`)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {t(`ImportProspectsPage.tableColumnFileName`)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {t(`ImportProspectsPage.tableColumnIRBNumber`)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {t(`ImportProspectsPage.tableColumnSourceType`)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {t(`ImportProspectsPage.tableColumnStatus`)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {t(`ImportProspectsPage.tableColumnImported`)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {t(`ImportProspectsPage.tableColumnFailed`)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {t(`ImportProspectsPage.tableColumnImportedBy`)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {t(`ImportProspectsPage.tableColumnImportedDate`)}
                  </MuiTableCell>
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody>
                {tableLoader ? (
                  <MuiTableRow key="-1">
                    <MuiTableCell colSpan="8" align="center">
                      <MuiCircularProgress size={35} />
                    </MuiTableCell>
                  </MuiTableRow>
                ) : (
                  studyImports &&
                  addReverseIndexToElements(
                    studyImports,
                    studyImports.length, // Rows per Page
                    defaultPageNumber,
                    studyImports.length // Total Count
                  ).map((row, index) => (
                    <MuiTableRow key={row.id}>
                      <MuiTableCell>{row.index}</MuiTableCell>
                      <MuiTableCell>
                        <MuiGrid container>
                          <MuiGrid item xs={10}>
                            <PEExport
                              displayName={row.filename}
                              exportURL={`${GET_IMPORTS_URL_BASE}/${row.id}/uploaded-file`}
                            />
                          </MuiGrid>
                          <MuiGrid item xs={2}>
                            {row.status === "UPLOADED" && (
                              <MuiCircularProgress size={20} />
                            )}
                          </MuiGrid>
                        </MuiGrid>
                      </MuiTableCell>
                      <MuiTableCell>{row.irbNumber}</MuiTableCell>
                      <MuiTableCell>
                        {t("ProspectSourceType." + row.source)}
                      </MuiTableCell>
                      <MuiTableCell>{mapStatus(row.status)}</MuiTableCell>
                      <MuiTableCell>
                        {row.summary
                          ? row.summary.rows - row.summary.errors
                          : 0}
                      </MuiTableCell>
                      <MuiTableCell>
                        <div className={classes.failedCellContainer}>
                          {row.summary.errors}
                          {row.summary.report &&
                            row.summary.report.length > 0 && (
                              <>
                                <MuiInfoIcon
                                  color="primary"
                                  onClick={() =>
                                    displayImportFailureDialog(index)
                                  }
                                />
                                {openDialog &&
                                  index === selectedImportRequest && (
                                    <PEErrorDialog
                                      onClose={closeImportFailureDialog}
                                      open={openDialog}
                                      errorsList={row.summary.report}
                                      title={t(
                                        `ImportProspectsPage.importFailureSummaryTitle`
                                      )}
                                      primarySubHeading={t(
                                        `ImportProspectsPage.importFailureLineNumber`
                                      )}
                                      primaryContent={
                                        errorSummaryContent.primaryContent
                                      }
                                      secondaryContent={
                                        errorSummaryContent.secondaryContent
                                      }
                                    />
                                  )}
                              </>
                            )}
                        </div>
                      </MuiTableCell>
                      <MuiTableCell>{row.importedBy}</MuiTableCell>
                      <MuiTableCell>
                        {new Date(row.timestamp).toLocaleString()}
                      </MuiTableCell>
                    </MuiTableRow>
                  ))
                )}
              </MuiTableBody>
            </MuiTable>
          </MuiTableContainer>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  ) : null;
};

export default ImportProspectsPageContent;
