import styled from "styled-components/macro";
import { Chip as MuiChip } from "@material-ui/core";

const PEChip = styled(MuiChip)`
  && {
    margin: 2px;
  }
`;

export default PEChip;
