import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableHead as MuiTableHead,
  Checkbox as MuiCheckbox,
  Tooltip as MuiTooltip,
  TableSortLabel as MuiTableSortLabel,
  Paper as MuiPaper,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TextField as MuiTextField,
} from "@material-ui/core";
import AntSwitch from "../shared/UI/AntSwitch";

export const useStyles = makeStyles((theme) => ({}));

const CategoryBgColors = ["inherit", "#F0F4F5", "#009abb"];
const CategoryFgColors = ["inherit", "inherit", "#009abb"];

export const TargetAccrualsRoot = styled(MuiPaper)`
  && {
    width: 1200px;
    min-width: "180em";
    margin: 0 auto 0 auto;
  },
`;

const StudyTableHead = styled(MuiTableHead)`
  && {
    background-color: #666666;
  }
`;

const EmptyTableCell = styled(MuiTableCell)`
  && {
    /* this is to override default table cell border-bottom */
    border-bottom: none;
  }
`;

const StudyTableCell = styled(MuiTableCell)`
  && {
    /* this is to override default table cell padding 16px */
    padding: 8px;
  }
`;

const StudyTextField = styled(MuiTextField)`
  && {
    display: "inline";
    text-align: right !important;
    max-width: "10em";
    & input {
      text-align: right;
      padding: 2px 2px 3px 0;
      font-size: 86%;
    }

    & input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    & input[type="number"] {
      -moz-appearance: textfield;
    }

    &
      .MuiOutlinedInput-root.Mui-disabled.Mui-error
      .MuiOutlinedInput-notchedOutline {
      border-color: #f44336;
    }
  }
`;

const TotalField = styled(StudyTextField)`
  && {
    & input {
      font-weight: bold;
    }

    & .MuiInputBase-root.Mui-disabled input {
      color: rgba(0, 0, 0, 0.87);
    }

    & .MuiInputBase-root.Mui-disabled.Mui-error input {
      color: #f44336;
    }

    &
      .MuiOutlinedInput-root.Mui-disabled.Mui-error
      .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0);
    }
    & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0);
      /* transparent*/
    }
  }
`;

const StudyTableHeadCell = styled(StudyTableCell)`
  && {
    max-width: ${(props) =>
      props.headcell.maxWidth ? props.headcell.maxWidth : "auto"};

    min-width: ${(props) =>
      props.headcell.minWidth ? props.headcell.minWidth : "auto"};
    width: ${(props) => (props.headcell.width ? props.headcell.width : "auto")};

    color: white;
    &&:hover {
      color: white;
    }
    &&:focus {
      color: white;
    }
    &&.active {
      color: white;
    }
  }
`;

const StudyTableSwitch = styled(AntSwitch)``;

const StudyTableSortLabel = styled(({ ...rest }) => (
  <MuiTableSortLabel classes={{ active: "active" }} {...rest} />
))`
  && {
    color: white;
    &&:hover {
      color: white;
    }
    &&:focus {
      color: white;
    }
    &&.active {
      color: white;
    }
    && > .icon {
      color: white !important;
    }
  }
`;

const SortLabelSpan = styled.span`
  border: 0px;
  clip: "rect(0 0 0 0)";
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  top: 20px;
  width: 1px;
`;

const TwoRowHeadCell = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
`;

const SelectAllCheckbox = styled(MuiCheckbox)`
  && {
    span {
      color: #ffffff;
    }
  }
`;

const StudyTableRow = styled(MuiTableRow)`
  && {
    &.totals-row {
      .MuiTableCell-body {
        font-weight: bold;
      }
    }

    background-color: ${(props) =>
      props.highlightrow === "true"
        ? "#EEEEEE"
        : props.althighlight
        ? CategoryBgColors[props.althighlight]
        : "inherit"};

    & .MuiTableCell-body {
      color: ${(props) =>
        props.highlightrow === "true"
          ? "inherit"
          : props.althighlight
          ? CategoryFgColors[props.althighlight]
          : "inherit"};
    }
  }
`;

const TableRowCell = styled(StudyTableCell)`
  && {
    vertical-align: top;
    max-width: 100px;
    word-break: break-word;

    .MuiFormHelperText-contained {
      margin-left: 57px;
      margin-right: 1px;
    }
    &.negative {
      color: #ff9800;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;

const TagContainer = styled.div`
  margin-top: 10px;
`;

const LightTooltip = styled(({ className, ...other }) => (
  <MuiTooltip classes={{ tooltip: className }} {...other} />
))`
  && {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: theme.shadows[1];
    font-size: 12px;
  }
`;

TargetAccrualsRoot.StudyTableHead = StudyTableHead;
TargetAccrualsRoot.StudyTableHead.StudyTableHeadCell = StudyTableHeadCell;
TargetAccrualsRoot.StudyTableHead.StudyTableSortLabel = StudyTableSortLabel;
TargetAccrualsRoot.StudyTableHead.SortLabelSpan = SortLabelSpan;
TargetAccrualsRoot.StudyTableHead.TwoRowHeadCell = TwoRowHeadCell;
TargetAccrualsRoot.StudyTableHead.SelectAllCheckbox = SelectAllCheckbox;
TargetAccrualsRoot.EmptyTableCell = EmptyTableCell;
TargetAccrualsRoot.StudyTableRow = StudyTableRow;
TargetAccrualsRoot.TableRowCell = TableRowCell;
TargetAccrualsRoot.MuiTextField = StudyTextField;
TargetAccrualsRoot.TotalField = TotalField;
TargetAccrualsRoot.FlexContainer = FlexContainer;
TargetAccrualsRoot.TagContainer = TagContainer;
TargetAccrualsRoot.Switch = StudyTableSwitch;
TargetAccrualsRoot.LightTooltip = LightTooltip;
