import {
  makeStyles as MuimakeStyles,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  Paper as MuiPaper,
} from "@material-ui/core";
import styled from "styled-components/macro";

export const useStyles = MuimakeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  ProseMirrorFocused: {
    outline: "none",
  },
  editorContent: {
    "& a:hover": {
      cursor: "pointer",
    },
  },
}));

export const buttonStyle = {
  margin: "2px 5px",
};

const CategoryBgColors = ["inherit", "#F0F4F5", "#009abb"];
const CategoryFgColors = ["inherit", "inherit", "#009abb"];

export const TemplatesConfigRoot = styled(MuiPaper)`
  && {
    width: 100%;
    min-width: "100em";
    margin: 0 auto 0 auto;
  },
`;

const StudyTableHead = styled(MuiTableHead)`
  && {
    background-color: #666666;
  }
`;

const StudyTableRow = styled(MuiTableRow)`
  && {
    &.totals-row {
      .MuiTableCell-body {
        font-weight: bold;
      }
    }

    background-color: ${(props) =>
      props.highlightrow === "true"
        ? "#EEEEEE"
        : props.althighlight
        ? CategoryBgColors[props.althighlight]
        : "inherit"};

    & .MuiTableCell-body {
      color: ${(props) =>
        props.highlightrow === "true"
          ? "inherit"
          : props.althighlight
          ? CategoryFgColors[props.althighlight]
          : "inherit"};
    }
  }
`;

TemplatesConfigRoot.StudyTableHead = StudyTableHead;
TemplatesConfigRoot.StudyTableRow = StudyTableRow;
