import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  TableContainer as MuiTableContainer,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  List as MuiList,
  ListItem as MuiListItem,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  TablePagination as MuiTablePagination,
  TableSortLabel as MuiTableSortLabel,
} from "@material-ui/core";
import { Check as MuiCheckIcon, Edit as MuiEditIcon } from "@material-ui/icons";
import { useAlerts } from "common";
import { useStyles } from "app/prospect/ProspectListingPageStyles";
import PEMail from "app/shared/UI/PEMail";
import { getAllLookupProspects } from "app/services/prospectServices";
import { useProspectContext } from "app/services/prospectContext";
import { maskDOB, maskEmailAddress, maskPhoneNumber } from "app/shared/utils";

const ProspectListingPageContent = (props) => {
  const classes = useStyles();
  const { filters, initialLoad, pagination, setPagination, sort, setSort } =
    useProspectContext();

  const { t } = useTranslation();
  let history = useHistory();
  const [lookupProspectList, setLookupProspectList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { setAlert } = useAlerts();

  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    var pageValue = pagination.page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - pagination.page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      pageValue = 0;
    }
    setPagination({
      page: pageValue,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleEditProspect = (rowData) => {
    if (rowData) {
      history.push({
        pathname: `/prospects/${rowData.mrn}/edit`,
        state: { prospect: rowData },
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      sort.orderBy === property && sort.order === "asc" ? "desc" : "asc";
    setSort({
      orderBy: property,
      order: isAsc,
    });
  };

  const createSortHandler = (property) => (event) =>
    handleRequestSort(event, property);

  useEffect(() => {
    getAllLookupProspects(
      setLookupProspectList,
      setTotalCount,
      setAlert,
      filters,
      pagination,
      sort
    );
    // eslint-disable-next-line
  }, [pagination, sort, filters]);

  const prospectHeadCells = [
    { id: "mrn", label: "tableColumnMRN", sort: true },
    { id: "firstName", label: "tableColumnFirstName", sort: true },
    { id: "lastName", label: "tableColumnLastName", sort: true },
    { id: "email", label: "tableColumnEmail", width: 300 },
    { id: "phone", label: "tableColumnPhone", width: 135 },
    { id: "birthDate", label: "tableColumnDOB", width: 135, sort: true },
    { id: "address", label: "tableColumnAddress" },
    { id: "note", label: "tableColumnNote" },
    { id: "optOut", label: "tableColumnOptOut" },
    { id: "concern", label: "tableColumnConcernOnFile" },
    { id: "action", label: "tableColumnAction" },
  ];

  return (
    <MuiGrid container className={classes.LookupProspectPageCustomPadding}>
      <MuiTableContainer component={MuiPaper}>
        <MuiTable stickyHeader>
          <MuiTableHead>
            <MuiTableRow>
              {prospectHeadCells.map((headCell) => (
                <MuiTableCell
                  key={headCell.id}
                  width={headCell.width ? headCell.width : ""}
                >
                  {headCell.sort ? (
                    <MuiTableSortLabel
                      active={sort.orderBy === headCell.id}
                      direction={
                        sort.orderBy === headCell.id ? sort.order : "asc"
                      }
                      onClick={createSortHandler(headCell.id)}
                    >
                      {t(`LookupProspect.${headCell.label}`)}
                    </MuiTableSortLabel>
                  ) : (
                    t(`LookupProspect.${headCell.label}`)
                  )}
                </MuiTableCell>
              ))}
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody className={classes.customTableCell}>
            {lookupProspectList && lookupProspectList.length ? (
              lookupProspectList.map((lookupProspectRow, lookupProspectIdx) => (
                <MuiTableRow key={lookupProspectIdx}>
                  <MuiTableCell>{lookupProspectRow.mrn}</MuiTableCell>
                  <MuiTableCell>{lookupProspectRow.firstName}</MuiTableCell>
                  <MuiTableCell>{lookupProspectRow.lastName}</MuiTableCell>
                  <MuiTableCell>
                    <MuiList>
                      {lookupProspectRow.emails &&
                        lookupProspectRow.emails.map((emailRow, emailIdx) => (
                          <MuiListItem key={emailIdx}>
                            <PEMail emailAddress={emailRow}>
                              <MuiTooltip title={emailRow} arrow>
                                <span>{maskEmailAddress(emailRow)}</span>
                              </MuiTooltip>
                            </PEMail>
                          </MuiListItem>
                        ))}
                    </MuiList>
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiList>
                      {lookupProspectRow.phones &&
                        lookupProspectRow.phones.map((phoneRow, phoneIdx) => (
                          <MuiListItem key={phoneIdx}>
                            <MuiTypography>
                              <MuiTooltip title={phoneRow} arrow>
                                <span>{maskPhoneNumber(phoneRow)}</span>
                              </MuiTooltip>
                            </MuiTypography>
                          </MuiListItem>
                        ))}
                    </MuiList>
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiTooltip title={lookupProspectRow.birthDate} arrow>
                      <span>
                        {lookupProspectRow.birthDate &&
                          maskDOB(lookupProspectRow.birthDate)}
                      </span>
                    </MuiTooltip>
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiList>
                      {lookupProspectRow.addresses &&
                        lookupProspectRow.addresses.map(
                          (addressRow, addressIdx) => (
                            <MuiListItem key={addressIdx}>
                              <MuiTooltip title={addressRow} arrow>
                                <MuiTypography
                                  componnent="span"
                                  variant="body2"
                                  className={classes.customNoteTableCell}
                                >
                                  {addressRow}
                                </MuiTypography>
                              </MuiTooltip>
                            </MuiListItem>
                          )
                        )}
                    </MuiList>
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiTooltip title={lookupProspectRow.note} arrow>
                      <span className={classes.customNoteTableCell}>
                        {lookupProspectRow.note}
                      </span>
                    </MuiTooltip>
                  </MuiTableCell>
                  <MuiTableCell>
                    {lookupProspectRow.optOut && (
                      <MuiCheckIcon color="primary" />
                    )}
                  </MuiTableCell>
                  <MuiTableCell>
                    {lookupProspectRow.concern && (
                      <MuiCheckIcon color="primary" />
                    )}
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiTooltip title={t(`LookupProspect.editProspect`)} arrow>
                      <MuiIconButton
                        size="small"
                        onClick={(e) => handleEditProspect(lookupProspectRow)}
                      >
                        <MuiEditIcon />
                      </MuiIconButton>
                    </MuiTooltip>
                  </MuiTableCell>
                </MuiTableRow>
              ))
            ) : !initialLoad ? (
              <MuiTableRow>
                <MuiTableCell colSpan={10} align="center">
                  {t(`LookupProspect.emptyTableRow`)}
                </MuiTableCell>
              </MuiTableRow>
            ) : (
              <MuiTableRow>
                <MuiTableCell colSpan={10} align="center">
                  {t(`LookupProspect.messageOnTableInitialLoad`)}
                </MuiTableCell>
              </MuiTableRow>
            )}
          </MuiTableBody>
        </MuiTable>
        <MuiTablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </MuiTableContainer>
    </MuiGrid>
  );
};

export default ProspectListingPageContent;
