import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  Button as MuiButton,
  ListItemText as MuiListItemText,
  Divider as MuiDivider,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  List as MuiList,
  ListItem as MuiListItem,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Warning as MuiWarningIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import { renderContentBySplitingOnFullStop } from "../utils";
import { useStyles } from "app/shared/UI/PEErrorDialogStyles";

const PEErrorDialog = (props) => {
  const {
    onClose,
    open,
    errorsList,
    title,
    primarySubHeading,
    secondarySubHeading,
    primaryContent,
    secondaryContent,
    displayDynamicPrimarySubHeading,
  } = props;
  const [scroll] = useState("paper");
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <MuiDialog open={open} onClose={onClose} scroll={scroll}>
      <MuiDialogTitle
        id="error-dialog-title"
        className={classes.errorSummaryTitle}
      >
        <MuiWarningIcon className={classes.warning} />
        {title}
      </MuiDialogTitle>
      <MuiDialogContent className={classes.errorSummaryContent}>
        <MuiList>
          {errorsList.map((content, index) => {
            return (
              <div key={index}>
                {index > 0 && <MuiDivider component="li" />}
                <MuiListItem
                  alignItems="flex-start"
                  className={classes.contentListItem}
                >
                  <MuiListItemText
                    primary={
                      <span className={classes.itemTextBlock}>
                        {displayDynamicPrimarySubHeading ? (
                          <span className={classes.itemTextSpan}>
                            {content.type}
                          </span>
                        ) : (
                          primarySubHeading && (
                            <span className={classes.itemTextSpan}>
                              {primarySubHeading}
                            </span>
                          )
                        )}
                        <MuiTypography
                          variant="body1"
                          className={classes.MuiTypography}
                        >
                          {content[primaryContent]}
                        </MuiTypography>
                      </span>
                    }
                    secondary={
                      <span component="span" className={classes.itemTextBlock}>
                        {secondarySubHeading && (
                          <span className={classes.itemTextSpan}>
                            {secondarySubHeading}
                          </span>
                        )}
                        <MuiTypography
                          component="span"
                          className={classes.MuiTypography}
                        >
                          {renderContentBySplitingOnFullStop(
                            content[secondaryContent]
                          )}
                        </MuiTypography>
                      </span>
                    }
                  />
                </MuiListItem>
              </div>
            );
          })}
        </MuiList>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton onClick={onClose} color="primary">
          {t(`Close`)}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

PEErrorDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PEErrorDialog;
