import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import * as qs from "qs";
import { ProspectDefaults } from "app/shared/constants";
import { decodeBase64 } from "app/shared/utils";

export const ProspectContext = createContext();

export const ProspectProvider = ({ children }) => {
  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State for filters
  const queryString = qs.parse(location.search);
  const [filters, setFilters] = useState({
    firstName: decodeBase64(queryString.firstName),
    lastName: decodeBase64(queryString.lastName),
    phone: decodeBase64(queryString.phone),
    email: decodeBase64(queryString.email),
    mrn: decodeBase64(queryString.mrn),
    birthDate: decodeBase64(queryString.birthDate),
    isOptedOut: decodeBase64(queryString.isOptedOut),
    isHavingConcern: decodeBase64(queryString.isHavingConcern),
  });

  const [initialLoad, setInitialLoad] = useState(true);

  // State for Sort
  const [sort, setSort] = useState({
    orderBy: ProspectDefaults.PROSPECTDEFAULTORDERBY,
    order: ProspectDefaults.PROSPECTDEFAULTORDER,
  });

  // State for pagination
  const [pagination, setPagination] = useState({
    page: ProspectDefaults.PROSPECTDEFAULTPAGE,
    rowsPerPage: ProspectDefaults.PROSPECTDEFAULTPAGESIZE,
  });

  return (
    <ProspectContext.Provider
      value={{
        filters,
        setFilters,
        initialLoad,
        setInitialLoad,
        sort,
        setSort,
        pagination,
        setPagination,
      }}
    >
      {children}
    </ProspectContext.Provider>
  );
};

export const useProspectContext = () => {
  return useContext(ProspectContext);
};
