import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button as MuiButton,
  Dialog as MuiDialog,
} from "@material-ui/core";

// this is confirmation dialog
const ConfirmationDialog = (props) => {
  const { onClose, open, title, message, okLabel, cancelLabel } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <MuiDialog open={open}>
      <MuiDialogTitle id="confirmation-dialog-title">
        {title || ""}
      </MuiDialogTitle>
      <MuiDialogContent>{message || ""}</MuiDialogContent>
      <MuiDialogActions>
        <MuiButton autoFocus onClick={handleCancel} color="primary">
          {cancelLabel ? cancelLabel : "Cancel"}
        </MuiButton>
        <MuiButton onClick={handleOk} color="primary">
          {okLabel ? okLabel : "OK"}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default ConfirmationDialog;
