import { useHistory } from "react-router-dom";
import {
  Toolbar as MuiToolbar,
  Tooltip as MuiTooltip,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
} from "@material-ui/core";
import {
  ChevronLeft as MuiChevronLeftIcon,
  ArrowBack as MuiArrowBackIcon,
} from "@material-ui/icons";
import { useStyles } from "app/shared/UI/PEPageStyles";

const PEPageTitle = (props) => {
  let history = useHistory();
  const classes = useStyles();

  const {
    pageTitle,
    hasBackButton,
    hasDrawer,
    openDrawer,
    toggleDrawer,
    drawerIconTooltip,
    backButtonTooltip,
    backActionCB,
  } = props;

  return (
    <MuiToolbar className={classes.PageTitleContainer}>
      {hasDrawer && (
        <MuiTooltip
          title={drawerIconTooltip ? drawerIconTooltip : "Toggle Drawer"}
          placement="top"
          arrow
        >
          <MuiIconButton onClick={toggleDrawer} size="small">
            <MuiChevronLeftIcon
              className={openDrawer ? "" : classes.rotateIcon}
            />
          </MuiIconButton>
        </MuiTooltip>
      )}
      {hasBackButton && (
        <MuiTooltip
          title={
            backButtonTooltip ? backButtonTooltip : "Back to previous page"
          }
          placement="top"
          arrow
        >
          <MuiIconButton
            onClick={() => {
              if (backActionCB && backActionCB instanceof Function) {
                backActionCB();
              }

              history.goBack();
            }}
            size="small"
          >
            <MuiArrowBackIcon />
          </MuiIconButton>
        </MuiTooltip>
      )}
      <MuiTypography variant="h5" noWrap>
        {pageTitle}
      </MuiTypography>
    </MuiToolbar>
  );
};

export default PEPageTitle;
